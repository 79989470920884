import React from 'react';
import {Table} from 'antd';
import {useDispatch, useSelector} from "react-redux";
import {setFilter, setTablePagination} from "../../redux/accessoriesSlice";


const AccessoryTable = ({
                            dataLoading,
                            handleTableChange,
                            data,
                            handleShowArticle,
                            columns,
                        }) => {
    const pagination = useSelector((state) => state.accessories.pagination);
    const filterData = useSelector((state) => state.accessories.filter);
    const dispatch = useDispatch();

    return <Table
        dataSource={data?.components}
        columns={columns}
        loading={dataLoading}
        rowHoverable={false}
        rowClassName={'wheelTableRow'}
        pagination={pagination}
        size={"small"}
        rowKey={(row) => row.partNumber}
        onRow={(record) => {
            return {
                onClick: () => {
                    handleShowArticle(record)
                }
            }
        }}
        onChange={(currentPagination, filters, sorter) => {
            dispatch(setTablePagination({...pagination, ...currentPagination}))
            dispatch(setFilter({
                ...filterData,
                offset: (pagination.current - 1) * pagination.pageSize,
                limit: pagination.current * pagination.pageSize
            }))
            handleTableChange(currentPagination, filters, sorter)
        }}
    />;
};

export default AccessoryTable;
