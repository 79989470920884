import {Button, Flex, Row, Table, Typography} from "antd";
import {useNavigate} from "react-router-dom";
import React, {useContext} from "react";
import {HISTORY_QUERY} from "../apollo/apollo-queries";
import {useQuery} from "@apollo/client";
import {PlusIcon} from "@primer/octicons-react";
import {NotificationsContext} from "../components/common/NotificationsContext";

export const {Title, Text} = Typography;

export const Catalogs = () => {
    const {notifications} = useContext(NotificationsContext)
    const navigate = useNavigate();
    const {
        data: history,
        loading: historyLoading
    } = useQuery(HISTORY_QUERY, {
        errorPolicy: "all",
        onError: (error) => notifications.error(`Errore nel server: ${error.message}`)
    });
    const columns = [
        {
            title: 'ID',
            dataIndex: 'aaiaid',
        },
        {
            title: 'Nome fornitore',
            dataIndex: 'brandLabel',
        },
        {
            title: 'Fitment',
            dataIndex: 'fitments',
            render: (text) => getFormattedDate(text),
            sorter: (a, b) => a.fitments - b.fitments,
        },
        {
            title: 'Catalogo',
            dataIndex: 'catalog',
            render: (text) => getFormattedDate(text),
            sorter: (a, b) => a.catalog - b.catalog,
        },
    ];

    const getFormattedDate = (timestamp) => {
        if (!timestamp || timestamp === "") return ""

        const date = new Date(parseInt(timestamp));
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return `${day}/${month}/${year} ${hours}:${minutes}`;
    }


    return <Flex vertical gap={"large"}>
        <Title level={3}>Storico</Title>
        <Row justify={"end"}>
            <Button icon={<PlusIcon/>} type={"primary"} onClick={() => navigate('new-catalog')}>
                Aggiungi catalogo
            </Button>
        </Row>
        <Table
            size={"small"}
            dataSource={history?.getHistory}
            columns={columns}
            loading={historyLoading}
            pagination={{pageSize: 10}}
            rowKey={(record) => record.id}/>
    </Flex>

}