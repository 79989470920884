import {gql} from '@apollo/client';

export const WHEELS_QUERY = gql(`
    query GetWheels($sort: SortInput!, $limit: Int, $offset: Int, $filters: WheelsFiltersInput) {
        getWheels(sort: $sort, limit: $limit, offset: $offset, filters: $filters) {
            lastUpdate
            totalDocuments
            wheels {
                PartNumber
                PartDescription
                Style
                Size
                InvOrderType
                ImageURL
                BoltPattern
                Offset
                CenterBore
                LoadRating
                ShippingWeight
                DisplayStyleNo
                Finish
                MSRP_EUR
                Brand
                TotalQOH
                RunDate
                InternalQOH
                discountPrice
                AmericaQOH
                EuropeQOH
            }
        }
    }
`);
export const WHEELS_FILTERS_QUERY = gql(`
    query GetWheelsFilters {
        getWheelsFilters {
            Brand
            Finish
            Offset
            CenterBore
            Width
            Diameter
            BoltPattern
        }
    }
`);
export const WHEELS_BRANDS_FILTERS_QUERY = gql(`
    query GetWheelsFilters {
        getWheelsFilters {
            Brand
        }
    }
`);
export const SUPPLIERS_QEURY = gql(`
    {
        getSuppliers {
            aaiaid
            brandLabel
        }
    }
`)

export const BRANDS_QEURY = gql(`
    query Brands($aaiaid: String) {
        getAllBrands(aaiaid: $aaiaid)
    }
`);

export const MODEL_QUERY = gql(`
    query Query($aaiaid: String, $make: String) {
        getAllBrandModels(aaiaid: $aaiaid, make: $make)
    }
`);

export const SUBMODELS_QUERY = gql(`
    query Query($aaiaid: String, $make: String, $model: String, $year: Int) {
        getAllSubmodels(aaiaid: $aaiaid, make: $make, model: $model, year: $year)
    }
`);

export const YEARS_QUERY = gql(`
    query Query($aaiaid: String, $make: String, $model: String, $submodel: String) {
        getAllBrandModelYears(aaiaid: $aaiaid, make: $make, model: $model, submodel: $submodel)
    }
`);

export const CATEGORIES_QUERY = gql(`
    query getCategories($make: String, $aaiaid: String, $model: String, $submodel: String) {
        getAllCategories(make: $make, aaiaid: $aaiaid, model: $model, submodel: $submodel)
    }
`);
export const COMPONENTS_QUERY = gql(`
    query ExampleQuery($offset: Int!, $limit: Int!, $fullText: String, $aaiaid: String, $make: String, $model: String, $year: Int, $submodel: String, $priceMin: Float, $priceMax: Float, $sortOrder: Int, $sortField: String) {
        getAllPertinentItems(offset: $offset, limit: $limit, fullText: $fullText, aaiaid: $aaiaid, make: $make, model: $model, year: $year, submodel: $submodel, priceMin: $priceMin, priceMax: $priceMax, sortOrder: $sortOrder, sortField: $sortField) {
            totalDocuments
            components {
                partNumber
                aaiaid
                brandLabel
                partTerminologyLabel
                category
                title
                price
                finalPrice
                width
                length
                height
                weight
                productImage
                warranty
                description
                availability
            }
        }
    }`);

export const COMPONENT_QUERY = gql(`
    query GetComponent($partNumber: String!, $aaiaid: String!) {
        getComponent(partNumber: $partNumber, aaiaid: $aaiaid) {
            partNumber
            aaiaid
            brandLabel
            partTerminologyLabel
            category
            title
            price
            finalPrice
            width
            length
            height
            weight
            productImage
            warranty
            description
        }
    }`);

export const MAPPINGS_QUERY = gql(`
    query GetMappings {
        getMappings {
            name
            mappedFields {
                ... on MappedComponent {
                    partNumber
                    aaiaid
                    brandLabel
                    partTerminologyLabel
                    category
                    title
                    price
                    width
                    length
                    height
                    weight
                    productImage
                    warranty
                    description
                }
                ... on Fitment {
                    partNumber
                    aaiaid
                    year
                    make
                    model
                    submodel
                    brandLabel
                }
            }
            appendFields
            type
        }
    }`);

export const DISCOUNTS_QUERY = gql(`
    query GetDiscounts {
        getDiscounts {
            name
            value
            valueType
        }
    }`);

export const TC_QUERY = gql(`
    query getShipmentCosts {
        getShipmentCosts {
            name
            value
            valueType
        }
    }`);

export const MARGIN_QUERY = gql(`
    query GetCharges {
        getCharges {
            name
            value
            valueType
        }
    }`);

export const STOCK_QUERY = gql(`
    query GetStock {
        getStock {
            article
            availableQuantity
            orderedQuantity
            description
            producerName
            catalogCheck
            price
            lastUpdate
        }
    }
`);

export const AVAILABILITY_QUERY = gql(`
    query Query($article: String!) {
        getAvailability(article: $article)
    }`);

export const COMPONENT_MAPPING_MUTATION = gql(`
    mutation AddComponentMapping($mapping: ComponentMappingInput) {
        addComponentMapping(mapping: $mapping) {
            name
        }
    }
`);

export const FITMENT_MAPPING_MUTATION = gql(`
    mutation AddFitmentMapping($mapping: FitmentMappingInput) {
        addFitmentMapping(mapping: $mapping) {
            name
            type
        }
    }
`);

export const HISTORY_QUERY = gql(`
    query GetHistory {
        getHistory {
            aaiaid
            brandLabel
            catalog
            fitments
        }
    }
`);

export const DELETE_COMPONENT_QUERY = gql(`
    mutation DeleteComponentMapping($name: String) {
        deleteComponentMapping(name: $name)
    }
`);

export const DELETE_FITMENT_QUERY = gql(`
    mutation DeleteFitmentMapping($name: String) {
        deleteFitmentMapping(name: $name)
    }
`);

export const ADD_DISCOUNT_QUERY = gql(`
    mutation SetDiscount($aaiaid: String!, $discount: Float!) {
        setDiscount(aaiaid: $aaiaid, discount: $discount)
    }
`);

export const ADD_TC_QUERY = gql(`
    mutation SetShimpentCost($aaiaid: String!, $cost: Float!) {
        setShimpentCost(aaiaid: $aaiaid, cost: $cost)
    }`);

export const VARIABLES_QUERY = gql(`
    query GetVariables {
        getVariables {
            name
            value
            valueType
        }
    }
`);

export const SET_LITERAL_VALUE = gql(`
    mutation SetLiteralVariable($name: String!, $value: String!) {
        setLiteralVariable(name: $name, value: $value)
    }
`);

export const SET_NUMERIC_VALUE = gql(`
    mutation SetNumericVariable($name: String!, $value: Float!) {
        setNumericVariable(name: $name, value: $value)
    }
`);

export const SET_MARGIN = gql(`
    mutation SetCharge($aaiaid: String!, $value: Float!) {
        setCharge(aaiaid: $aaiaid, value: $value)
    }`);

export const ADD_USER = gql(`
    mutation Mutation($roles: [String]!, $user: UserInput!) {
        addUser(roles: $roles, user: $user)
    }
`);

export const GET_USERS = gql(`
    query GetUsers {
        getUsers
    }
`);

export const GET_USERS_ROLES = gql(`
    query Query($user: String!) {
        getUserRoles(user: $user)
    }
`);

export const DELETE_USER = gql(`
    mutation DeleteCustomer($mail: String!) {
        deleteCustomer(mail: $mail)
    }
`);

export const DELETE_ROLE = gql(`
    mutation RemoveUserRole($user: String!, $roles: [String]!) {
        removeUserRole(user: $user, roles: $roles)
    }
`);

export const ASSIGN_ROLE = gql(`
    mutation AssignUserRole($user: String!, $roles: [String]!) {
        assignUserRole(user: $user, roles: $roles)
    }
`);

export const ADD_CUSTOMER_DISCOUNT = gql(`
    mutation AddCustomerDiscount($discount: CustomerDiscountInput!) {
        addCustomerDiscount(discount: $discount)
    }
`);

export const EDIT_CUSTOMER_DISCOUNT = gql(`
    mutation EditCustomerDiscount($discount: CustomerDiscountInput!) {
        editCustomerDiscount(discount: $discount)
    }
`);

export const DELETE_CUSTOMER_DISCOUNT = gql(`
    mutation DeleteCustomerDiscount($discount: CustomerDiscountInput!) {
        deleteCustomerDiscount(discount: $discount)
    }
`);

export const GET_CUSTOMER_DISCOUNT = gql(`
    query GetCustomerDiscounts($customer: String) {
        getCustomerDiscounts(customer: $customer) {
            user
            target
            brand
            discount
        }
    }
`);

export const WHEELS_SC_QUERY = gql(`
    query Query {
        getWheelExtraPrices {
            diameter
            valueUS
            valueUE
        }
    }
`);

export const REFRESH_WHEELS = gql(`
    query Query {
        refreshWheels
    }
`);

export const ADD_WHEELS_SC = gql(`
    mutation Mutation($extraPrices: [WheelExtraPriceInput]!) {
        addWheelExtraPrices(extraPrices: $extraPrices)
    }
`);

export const EDIT_WHEELS_SC = gql(`
    mutation EditWheelExtraPrice($extraPrice: WheelExtraPriceInput) {
        editWheelExtraPrice(extraPrice: $extraPrice)
    }
`);

export const ORDERS_QUERY = gql(`
    query Query {
        orders {
            id
            customer
            orderDate
            total
            status
            items {
                code
                quantity
                description
                brand
                unitPrice {
                    base
                    overprice
                    overpriceUS
                    discount
                    total
                }
                status
                trackingID
                deliveryOption
                category
            }
            notes
            customerNotes
            trackingID
            deliveryDate
            alternativeAddress
        }
    }
`);

export const ORDER_QUERY = gql(`

    query Order($orderId: ID!) {
        order(id: $orderId) {
            id
            customer
            orderDate
            total
            status
            items {
                code
                quantity
                description
                brand
                unitPrice {
                    base
                    overprice
                    overpriceUS
                    discount
                    total
                }
                status
                trackingID
                deliveryOption
                category
            }
            notes
            trackingID
            deliveryDate
            alternativeAddress
            customerNotes
        }
    }
`);

export const UPDATE_ORDER = gql(`
    mutation UpdateOrder($updateOrderId: ID!, $order: OrderInput) {
        updateOrder(id: $updateOrderId, order: $order)
    }
`);

export const ADD_ORDER = gql(`
    mutation Mutation($order: OrderInput) {
        addOrder(order: $order)
    }
`);

export const CUSTOMER_QUERY = gql(`
    query Query($mail: String) {
        customer(mail: $mail) {
            name
            surname
            phone
            email
            businessName
            fiscalCode
            notes
            street
            number
            postalCode
            city
            state
            sdi
            foreign
        }
    }
`);

export const UPDATE_CUSTOMER = gql(`
    mutation UpdateCustomer($customer: CustomerInput!) {
        updateCustomer(customer: $customer)
    }
`);

export const ADD_CUSTOMER = gql(`
    mutation Mutation($customer: CustomerInput!, $password: String!, $roles: [String]!) {
        addCustomer(customer: $customer, password: $password, roles: $roles)
    }
`);

export const CUSTOMERS_QUERY = gql(`
    query Customers {
        customers {
            name
            surname
            phone
            email
            businessName
            fiscalCode
            notes
            street
            number
            postalCode
            city
            state
            foreign
        }
    }
`);

export const CHANGE_PASSWORD = gql(`
    query Query($mail: String, $newPassword: String) {
        resetPassword(mail: $mail, newPassword: $newPassword)
    }
`);

export const ALL_ROLES = gql(`
    query Roles {
        roles {
            description
            name
        }
    }
`);


export const PHONE_QUERY = gql(`
    query Customer($mail: String) {
        customer(mail: $mail) {
            phone
        }
    }
`);

export const PAINTS_QUERY = gql(`
    query Paints {
      paints {
        article
        availableQuantity
        orderedQuantity
        description
        producerName
        catalogCheck
        price
        lastUpdate
        category
        boxQuantity
      }
    }
`);

export const LUBRICANTS_QUERY = gql(`
    query Lubricants {
      lubricants {
        article
        availableQuantity
        orderedQuantity
        description
        producerName
        catalogCheck
        price
        lastUpdate
        category
        boxQuantity
      }
    }
`);

export const PARTS_QUERY = gql(`
    query parts {
      parts {
        article
        availableQuantity
        orderedQuantity
        description
        producerName
        catalogCheck
        price
        lastUpdate
        category
        boxQuantity
      }
    }
`);

export const BRANDS_CATEGORY = gql(`
query Brands {
  brands {
    category
    name
  }
}
`)
