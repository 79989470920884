import React, {useContext} from "react";
import {ApolloClient, ApolloProvider, HttpLink, InMemoryCache,} from '@apollo/client';
import {setContext} from '@apollo/link-context';
import {useAuth0} from '@auth0/auth0-react';
import {offsetLimitPagination} from "@apollo/client/utilities";
import {NotificationsContext} from "../components/common/NotificationsContext";

export const AuthorizedApolloProvider = ({children}) => {
    const {notifications} = useContext(NotificationsContext)
    const {getAccessTokenSilently} = useAuth0();

    const httpLink = new HttpLink({
        uri: `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_ADDRESS}/graphql`,
    });

    const authLink = setContext(async (_, {headers, ...rest}) => {
        let token;
        try {
            token = await getAccessTokenSilently();
        } catch (error) {
            notifications.error(`Errore nel server: ${error.message}`);
        }

        if (!token) return {headers, ...rest};

        return {
            ...rest,
            headers: {
                ...headers,
                authorization: `Bearer ${token}`,
            },
        };
    });

    const client = React.useRef();

    if (!client.current) {
        client.current = new ApolloClient({
            link: authLink.concat(httpLink),
            cache: new InMemoryCache({
                typePolicies: {
                    Query: {
                        fields: {
                            ComponentsQueryResult: offsetLimitPagination()
                        }
                    }
                },
                addTypename: false
            }),
        });
    }

    return (
        <ApolloProvider client={client.current}>
            {children}
        </ApolloProvider>
    );
};
