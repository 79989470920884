import React from 'react';
import {Table} from 'antd';
import {useDispatch} from "react-redux";


const GeneralTable = ({
                          dataLoading,
                          data,
                          handleShowArticle,
                          columns,
                          pagination,
                          setTablePagination,
                          setSort
                      }) => {
    const dispatch = useDispatch();
    return <Table
        dataSource={data}
        columns={columns}
        loading={dataLoading}
        rowHoverable={false}
        rowClassName={'wheelTableRow'}
        pagination={pagination}
        size={"small"}
        rowKey={(row) => row.partNumber}
        onRow={(record) => {
            return {
                onClick: () => {
                    handleShowArticle(record)
                }
            }
        }}
        onChange={(currentPagination, _, sorter) => {
            dispatch(setTablePagination(currentPagination));
            dispatch(setSort(sorter))
        }}
    />;
};

export default GeneralTable;
