import React, {useEffect} from 'react';
import {Button, Checkbox, Col, Form, Input, Row, Select} from 'antd';
import {SearchOutlined} from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";
import {setFilter} from "../../redux/wheelsSlice";

const {Option} = Select;

const FilterForm = ({filters, onSubmit}) => {
    const [form] = Form.useForm();
    const filter = useSelector((state) => state.wheels.filter);
    const dispatch = useDispatch();

    const onFinish = async (values) => {
        // Handle form submission logic here
        dispatch(setFilter({...filter, filters: {...filter.filters, ...values}}))
        const variables = {
            offset: filter.offset,
            limit: filter.limit,
            filters: Object.keys(filter.filters).reduce((obj, key) => {
                if (values[key] && values[key] !== "") {
                    return {...obj, [key]: values[key]}
                } else {
                    return obj
                }
            }, {})
        }
        await onSubmit(variables);
    };
    const onReset = async () => {
        form.resetFields();
        const variables = {
            offset: filter.offset, limit: filter.limit, filters: {}
        }
        await onSubmit(variables);
    }

    useEffect(() => {
        form.setFieldsValue(filter.filters)
    }, []);

    return (<Form
        form={form}
        onFinish={onFinish}
        initialValues={{
            OnlyInternal: '',
            Brand: '',
            Width: '',
            MinOffset: '',
            MaxOffset: '',
            Finish: '',
            CenterBore: '',
            Diameter: '',
            BoltPattern: '',
            PartNumber: '',
            Beadlock: false
        }}
        className={'filter-form'}
        layout={'vertical'}
    >
        <Row>
            <Col span={24}>
                <Form.Item name="PartNumber" className={'mb-1'}>
                    <Input placeholder={`Digita il termine di ricerca...`} prefix={<SearchOutlined/>}/>
                </Form.Item>
            </Col>
        </Row>
        <Row gutter={16}>
            <Col span={6}>
                <Form.Item name="Brand" label="Marca" className={'mb-1'}>
                    <Select showSearch
                            placeholder="Seleziona Marca">
                        <Option key="option-null" value={''}>Scegli un'opzione</Option>
                        {filters && [...filters.Brand].sort((a, b) => a.localeCompare(b)).map(((item, idx) => <Option
                            key={idx}
                            value={item}>{item}</Option>))}
                    </Select>
                </Form.Item>
            </Col>
            <Col span={6}>
                <Form.Item name="Diameter" label="Diametro" className={'mb-1'}>
                    <Select
                        showSearch
                        placeholder="Seleziona Diametro">
                        <Option key="option-null" value={''}>Scegli un'opzione</Option>
                        {filters && [...filters.Diameter].sort((a, b) => parseFloat(a) > parseFloat(b) ? 1 : -1).map(((item, idx) =>
                            <Option key={idx} value={item}>{item}</Option>))}
                    </Select>
                </Form.Item>
            </Col>
            <Col span={6}>
                <Form.Item name="Width" label="Larghezza" className={'mb-1'}>
                    <Select
                        showSearch
                        placeholder="Seleziona Larghezza">
                        <Option key="option-null" value={''}>Scegli un'opzione</Option>
                        {filters && [...filters.Width].sort((a, b) => parseFloat(a) > parseFloat(b) ? 1 : -1).map(((item, idx) =>
                            <Option key={idx} value={item}>{item}</Option>))}
                    </Select>
                </Form.Item>
            </Col>
            <Col span={6}>
                <Form.Item name="BoltPattern" label="Bolt Pattern" className={'mb-1'}>
                    <Select
                        placeholder="Seleziona Larghezza"
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) => {
                            return (option?.value ?? '').includes(input)
                        }}
                        filterSort={(optionA, optionB) =>
                            (optionA?.value ?? '').toLowerCase().localeCompare((optionB?.value ?? '').toLowerCase())
                        }
                    >
                        <Option key="option-null" value={''}>Scegli un'opzione</Option>
                        {filters && [...filters.BoltPattern].sort((a, b) => parseFloat(a) > parseFloat(b) ? 1 : -1).map(((item, idx) =>
                            <Option key={item} value={item}>{item}</Option>))}
                    </Select>
                </Form.Item>
            </Col>

        </Row>
        <Row gutter={16}>
            <Col span={6}>
                <Form.Item name="MinOffset" label="Offset Min" className={'mb-1'}>
                    <Select
                        showSearch
                        placeholder="Seleziona Offset Minimo">
                        <Option key="option-null" value={''}>Scegli un'opzione</Option>
                        {filters && [...filters.Offset].sort((a, b) => parseFloat(a) > parseFloat(b) ? 1 : -1).map(((item, idx) =>
                            <Option key={idx} value={item}>{item}</Option>))}
                    </Select>
                </Form.Item>
            </Col>
            <Col span={6}>
                <Form.Item name="MaxOffset" label="Offset Max" className={'mb-1'}>
                    <Select placeholder="Seleziona Offset Massimo">
                        <Option key="option-null" value={''}>Scegli un'opzione</Option>
                        {filters && [...filters.Offset].sort((a, b) => parseFloat(a) > parseFloat(b) ? 1 : -1).map(((item, idx) =>
                            <Option key={idx} value={item}>{item}</Option>))}
                    </Select>
                </Form.Item>
            </Col>
            <Col span={6}>
                <Form.Item name="Finish" label="Finitura" className={'mb-1'} labelCol={'10'}>
                    <Select
                        showSearch
                        placeholder="Seleziona Finitura">
                        <Option key="option-null" value={''}>Scegli un'opzione</Option>
                        {filters && [...filters.Finish].sort((a, b) => a.localeCompare(b)).map(((item, idx) => <Option
                            key={idx}
                            value={item}>{item}</Option>))}
                    </Select>
                </Form.Item>
            </Col>
            <Col span={6}>
                <Form.Item name="CenterBore" label="Foro Centrale" className={'mb-3'} labelCol={'10'}>
                    <Select
                        showSearch
                        placeholder="Seleziona Foro Centrale">
                        <Option key="option-null" value={''}>Scegli un'opzione</Option>
                        {filters && [...filters.CenterBore].sort((a, b) => a.localeCompare(b)).map(((item, idx) =>
                            <Option
                                key={idx}
                                value={item}>{item}</Option>))}
                    </Select>
                </Form.Item>
            </Col>
        </Row>
        <div className={'d-inline-flex w-100 mb-2'}>
            <Form.Item name="Beadlock" valuePropName="checked" className={'mb-1 w-25'}>
                <Checkbox>Solo BEADLOCK</Checkbox>
            </Form.Item>
            <Form.Item name="OnlyInternal" valuePropName="checked" className={'mb-1 w-25'}>
                <Checkbox>Disponibili solo in magazzino interno</Checkbox>
            </Form.Item>
        </div>
        <Row>
            <Col span={12}>
                <div className={"d-inline-flex justify-content-start"}>
                    <Button type="default" htmlType="button" onClick={onReset}>
                        Reset
                    </Button>
                </div>
            </Col>
            <Col span={12}>
                <div className={"d-flex justify-content-end"}>
                    <Button type="primary" htmlType="submit">
                        Cerca
                    </Button>
                </div>
            </Col>
        </Row>
    </Form>);
};

export default FilterForm;
