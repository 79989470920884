import {Flex, Typography} from "antd";
import {useQuery} from "@apollo/client";
import {CUSTOMERS_QUERY} from "../apollo/apollo-queries";
import UserTable from "../components/UserManagement/UserTable";

export const {Title, Text} = Typography;

export const UserManagement = () => {
    const {
        data, refetch, loading
    } = useQuery(CUSTOMERS_QUERY, {
        errorPolicy: "all",
    });

    return <Flex vertical={true}>
        <Title level={3}>GESTIONE UTENTI</Title>
        <UserTable users={data} refetch={refetch} loading={loading}></UserTable>
    </Flex>

}