import {Button, Flex, Form, Input, InputNumber, Select, Table} from "antd";
import {Title} from "../../views/Home";
import React, {useContext, useState} from "react";
import {useMutation, useQuery} from "@apollo/client";
import {ADD_TC_QUERY, SUPPLIERS_QEURY, TC_QUERY} from "../../apollo/apollo-queries";
import {DeleteFilled, EditOutlined} from "@ant-design/icons";
import {NotificationsContext} from "../common/NotificationsContext";


export const TrasportationCosts = () => {
    const {notifications} = useContext(NotificationsContext)
    const {
        data: suppliers
    } = useQuery(SUPPLIERS_QEURY, {
        errorPolicy: "all",
        onCompleted: data => {
        },
        onError: error => {
            notifications.error(`Errore nel server: ${error.message}`)
        }
    });
    const {
        loading: tcLoading, data: originalTC, refetch: refetchTC
    } = useQuery(TC_QUERY, {
        errorPolicy: "all",
        onCompleted: data => {
        },
        onError: (error) => {
            notifications.error(`Errore nel server: ${error.message}`)
        }
    });
    const [addTC, {
        loading: addTCLoading
    }] = useMutation(ADD_TC_QUERY, {
        errorPolicy: "all", onCompleted: async () => {
            setUpdating(false);
            await refetchTC()
            form.resetFields()
        }, onError: (error => {
            notifications.error(`Errore nel server: ${error.message}`)
        })
    });

    const [form] = Form.useForm();
    const [updating, setUpdating] = useState()

    const columns = [
        {
            title: 'ID',
            dataIndex: 'name',
        },
        {
            title: 'Fornitore',
            dataIndex: 'name',
            render: (text) => suppliers?.getSuppliers.find(supplier => supplier.aaiaid === text)?.brandLabel
        },
        {
            title: 'Costo in €',
            dataIndex: 'value',
        },
        {
            width: '5%',
            render: (_, record) => <Flex gap={"small"}>
                <Button
                    type={"link"}
                    icon={<DeleteFilled/>}
                    onClick={() => {
                        alert("Funzionalita' non implementata!")
                    }}
                ></Button>
                <Button
                    type={"link"}
                    htmlType={"button"}
                    icon={<EditOutlined/>}
                    onClick={() => {
                        setUpdating(true)
                        form.setFieldsValue({
                            supplier: record.name,
                            value: parseFloat(record.value)
                        })
                    }}
                ></Button>
            </Flex>
        }
    ];

    const onFinish = async (values) => {
        await addTC({
            variables: {
                aaiaid: values.supplier, cost: parseFloat(values.value)
            }
        })
    }

    return <Flex vertical gap={"large"}>
        <Flex vertical={true}>
            <Title level={5}>{updating ? 'Aggiorna' : 'Aggiungi'} costo:</Title>
            <Form form={form} layout="inline" onFinish={onFinish} onReset={() => setUpdating(false)}>
                <Form.Item
                    name="supplier"
                    rules={[{required: true, message: 'Selezionare fornitore!'}]}
                >
                    <Input placeholder="Sigla fornitore"/>
                </Form.Item>

                <Form.Item
                    name="value"
                    rules={[{required: true, message: 'Inserire valore'}]}
                >
                    <InputNumber min={0} placeholder="Costo" style={{width: 200}}/>
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={addTCLoading}>
                        Fatto
                    </Button>
                </Form.Item>
                <Form.Item>
                    <Button type="default" htmlType="reset">
                        Reset
                    </Button>
                </Form.Item>
            </Form>
        </Flex>
        <Table
            size={"small"}
            dataSource={originalTC?.getShipmentCosts}
            loading={tcLoading}
            columns={columns}
            pagination={{pageSize: 10}}
            rowKey={(record) => record.name}
        />

    </Flex>

}