export const beautifyNumber = (x, decimals = 2) => {
    const n = Math.round(parseFloat(x.toString()) * (Math.pow(10, decimals))) / (Math.pow(10, decimals));
    const parts = n.toString().split('.');

    return `${parts[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")},${parts.length > 1 ? String(parts[1]).padStart(2, '0') : '00'}`;
}

export const getFormattedDate = (timestamp) => {
    if (!timestamp || timestamp === "") return ""

    const date = new Date(parseInt(timestamp));
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}`;
}

export const getDeliveryOption = (item) => {
    switch (item) {
        case 0:
            return '24/48 h'
        case 1:
            return '4/5 gg'
        case 2:
            return 'US'
        default:
            return 'n/d'
    }
}

export function isChromeOrOpera() {
    const userAgent = navigator.userAgent.toLowerCase();
    console.log(userAgent)
    const isChrome = /chrome/.test(userAgent);
    const isOpera = /opr/.test(userAgent);

    return isChrome || isOpera;
}
