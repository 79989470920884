import React, {useContext, useEffect, useState} from 'react';
import {Button, Col, Form, Input, InputNumber, Row, Select} from 'antd';
import {SearchOutlined} from "@ant-design/icons";
import {useLazyQuery, useQuery} from "@apollo/client";
import {
    BRANDS_QEURY,
    CATEGORIES_QUERY,
    MODEL_QUERY,
    SUBMODELS_QUERY,
    SUPPLIERS_QEURY,
    YEARS_QUERY
} from "../../apollo/apollo-queries";
import {NotificationsContext} from "../common/NotificationsContext";
import {useDispatch, useSelector} from "react-redux";
import {setFilter} from "../../redux/accessoriesSlice";

const {Option} = Select;

const PartsForm = ({onFinish}) => {
    const {notifications} = useContext(NotificationsContext)
    const filters = useSelector((state) => state.accessories.filter);
    const dispatch = useDispatch();

    const initialFiltersOptions = {
        categories: [],
        makes: [],
        models: [],
        submodels: [],
        years: [],
        suppliers: []
    }
    const [filterOptions, setFilterOptions] = useState(initialFiltersOptions);

    const [form] = Form.useForm();
    const {
        loading: suppliersLoading
    } = useQuery(SUPPLIERS_QEURY, {
        errorPolicy: "all",
        onCompleted: (data) => {
            if (data.getSuppliers)
                setFilterOptions((prevState) => ({...prevState, suppliers: data.getSuppliers}))
        },
        onError: (error => {
            notifications.error(`Errore ne server: ${error.message}`)
        })
    });

    const {
        loading: categoriesLoading,
        refetch: refetchCategories
    } = useQuery(CATEGORIES_QUERY, {
        variables: {},
        errorPolicy: "all",
        onCompleted: (data) => {
            if (data.getAllCategories)
                setFilterOptions((prevState) => ({...prevState, categories: data.getAllCategories}))
        },
        onError: (error => {
            notifications.error(`Errore ne server: ${error.message}`)
        })
    });

    const {
        loading: makesLoading,
        refetch: refetchMakes
    } = useQuery(BRANDS_QEURY, {
        errorPolicy: "all",
        onCompleted: (data) => {
            if (data.getAllBrands)
                setFilterOptions((prevState) => ({...prevState, makes: data.getAllBrands}))
        },
        onError: (error => {
            notifications.error(`Errore ne server: ${error.message}`)
        })
    });

    const [getModels, {
        loading: modelsLoading,
    }] = useLazyQuery(MODEL_QUERY, {
        errorPolicy: "all",
        onCompleted: (data) => {
            if (data.getAllBrandModels)
                setFilterOptions((prevState) => ({...prevState, models: data.getAllBrandModels}))
        },
        onError: (error => {
            notifications.error(`Errore ne server: ${error.message}`)
        })
    });

    const [getSubmodels, {
        loading: submodelsLoading,
    }] = useLazyQuery(SUBMODELS_QUERY, {
        errorPolicy: "all",
        onCompleted: (data) => {
            if (data.getAllSubmodels)
                setFilterOptions((prevState) => ({...prevState, submodels: data.getAllSubmodels}))
        },
        onError: (error => {
            notifications.error(`Errore ne server: ${error.message}`)
        })
    });

    const [getYears, {
        loading: yearsLoading,
    }] = useLazyQuery(YEARS_QUERY, {
        errorPolicy: "all",
        onCompleted: (data) => {
            if (data.getAllBrandModelYears)
                setFilterOptions((prevState) => ({...prevState, years: data.getAllBrandModelYears}))
        },
        onError: (error => {
            notifications.error(`Errore ne server: ${error.message}`)
        })
    });

    useEffect(() => {
        form.setFieldsValue(filters)
    }, []);

    return (<Form
        form={form}
        onFinish={(values) => {
            dispatch(setFilter({
                ...filters,
                ...values
            }));
            onFinish({variables: filters});
        }}
        onReset={() => {
            form.resetFields();
            const variables = {
                category: 'lubrificanti',
                offset: 0,
                limit: 10
            }
            onFinish({variables});
        }}
        initialValues={{
            fullText: undefined,
            priceMin: 0,
            priceMax: 0
        }}
        onValuesChange={async (changedValues) => {
            dispatch(setFilter({...filters, ...changedValues}))
            await refetchCategories(form.getFieldsValue());
        }}
        className={'filter-form'}
        layout={'vertical'}
    >
        <Row>
            <Col span={24}>
                <Form.Item name="fullText" className={'mb-1'}>
                    <Input placeholder={`Digita il termine di ricerca...`} prefix={<SearchOutlined/>}/>
                </Form.Item>
            </Col>
        </Row>

        <Row gutter={16}>
            <Col span={6}>
                <Form.Item name="aaiaid" label="Fornitore" className={'mb-1'}>
                    <Select
                        showSearch
                        allowClear
                        placeholder="Seleziona Fornitore"
                        filterOption={(input, option) => {
                            return option.children.toLowerCase().includes(input.toLowerCase());
                        }}
                        loading={suppliersLoading}
                        disabled={!filterOptions || filterOptions.suppliers.length === 0}
                        onChange={async (value) => {
                            if (value) {
                                await refetchMakes({aaiaid: value})
                            } else {
                                await refetchMakes();
                            }
                        }}
                    >
                        {
                            filterOptions?.suppliers.map((item, idx) => <Option key={idx}
                                                                                value={item.aaiaid}>{item.brandLabel}</Option>)
                        }
                    </Select>
                </Form.Item>
            </Col>
            <Col span={6}>
                <Form.Item name="make" label="Marchio" className={'mb-1'}>
                    <Select
                        showSearch
                        allowClear
                        placeholder="Seleziona Marchio"
                        disabled={!filterOptions || filterOptions.makes.length === 0}
                        loading={makesLoading}
                        onChange={async (value) => {
                            if (value) {
                                await getModels({variables: {aaiaid: form.getFieldValue('aaiaid'), make: value}});
                            }
                        }}
                    >

                        {filterOptions?.makes.map(((item, idx) => <Option
                            key={idx}
                            value={item}>{item}</Option>))}
                    </Select>
                </Form.Item>
            </Col>
            <Col span={6}>
                <Form.Item name="model" label="Modello" className={'mb-1'}>
                    <Select
                        showSearch
                        allowClear
                        placeholder="Seleziona Modello"
                        disabled={!filterOptions || filterOptions.models.length === 0}
                        loading={modelsLoading}
                        onChange={async (value) => {
                            if (value) {
                                await Promise.all([getSubmodels({
                                    variables: {
                                        aaiaid: form.getFieldValue('aaiaid'),
                                        make: form.getFieldValue('make'),
                                        model: value
                                    }
                                }),
                                    getYears({
                                        variables: {
                                            aaiaid: form.getFieldValue('aaiaid'),
                                            make: form.getFieldValue('make'),
                                            model: value
                                        }
                                    })])
                            }
                        }}
                    >
                        {filterOptions?.models.map(((item, idx) => <Option
                            key={idx}
                            value={item}>{item}</Option>))}
                    </Select>
                </Form.Item>
            </Col>
            <Col span={6}>
                <Form.Item name="submodel" label="Sottomodello" className={'mb-1'}>
                    <Select
                        showSearch
                        allowClear
                        placeholder="Seleziona Sottomodello" loading={submodelsLoading}
                        disabled={!filterOptions || filterOptions.submodels.length === 0}>
                        {filterOptions?.submodels.map(((item, idx) => <Option
                            key={idx}
                            value={item}>{item}</Option>))}
                    </Select>
                </Form.Item>
            </Col>

        </Row>
        <Row gutter={16}>
            <Col span={6}>
                <Form.Item name="year" label="Anno" className={'mb-1'}>
                    <Select
                        showSearch
                        allowClear
                        placeholder="Seleziona Anno"
                        disabled={!filterOptions || filterOptions.years.length === 0}> loading={yearsLoading}
                        {filterOptions?.years.map(((item, idx) => <Option
                            key={idx}
                            value={item}>{item}</Option>))}

                    </Select>
                </Form.Item>
            </Col>
            <Col span={6}>
                <Form.Item name="category" label="Cagtegoria" className={'mb-1'}>
                    <Select
                        showSearch
                        allowClear
                        placeholder="Seleziona Categoria" loading={categoriesLoading}
                        disabled={!filterOptions || filterOptions.categories.length === 0}>
                        <Option key="option-null" value={''}>Scegli un'opzione</Option>
                        {filterOptions?.categories.map(((item, idx) => <Option
                            key={idx}
                            value={item}>{item}</Option>))}
                    </Select>
                </Form.Item>
            </Col>
            <Col span={6}>
                <Form.Item name="minPrice" label="Prezzo minimo">
                    <InputNumber min={0} className={'w-100'} placeholder={'Prezzo minimo'}></InputNumber>
                </Form.Item>
            </Col>
            <Col span={6}>
                <Form.Item name="maxPrice" label="Prezzo massimo">
                    <InputNumber min={0} className={'w-100'} placeholder={'Prezzo massimo'}></InputNumber>
                </Form.Item>
            </Col>
        </Row>

        <Row>
            <Col span={12}>
                <div className={"d-inline-flex justify-content-start"}>
                    <Button type="default" htmlType="reset">
                        Reset
                    </Button>
                </div>
            </Col>
            <Col span={12}>
                <div className={"d-flex justify-content-end"}>
                    <Button type="primary" htmlType="submit">
                        Cerca
                    </Button>
                </div>
            </Col>
        </Row>
    </Form>);
};

export default PartsForm;
