import {Alert, Col, Drawer, Flex, Image, Row,} from "antd";
import {useQuery} from "@apollo/client";
import {PAINTS_QUERY} from "../apollo/apollo-queries";
import React, {useContext, useEffect, useState} from "react";
import {Text, Title} from "./Home";
import {CartCounterContext} from "../components/cart/CartCounterContext";
import {NotificationsContext} from "../components/common/NotificationsContext";
import placeholder from "../assets/placeholder.jpg";
import {beautifyNumber} from "../components/common/utils";
import {DiscountsContext} from "../components/common/DiscountContext";
import GeneralFilterForm from "../components/common/GeneralFilters";
import GeneralTable from "../components/common/GeneralTable";
import {GeneralDetail} from "../components/common/GeneralDetail";
import {useSelector} from "react-redux";
import {setFilter, setSort, setTablePagination} from "../redux/paintsSlice";

export const RaptorPaints = () => {
    const {getDiscount} = useContext(DiscountsContext);
    const {notifications} = useContext(NotificationsContext)
    const [visible, setVisible] = useState(false);
    const [selectedArticle, setSelectedArticle] = useState({});
    const [showDiscount, setShowDiscount] = useState(true);
    const [selectedArticleQuantity, setSelectedArticleQuantity] = useState([
        {
            key: 'availableQuantity', label: 'Disponibilità immediata', overprice: 0, quantity: 0
        }
    ]);
    const [filterFn, setFilterFn] = useState(() => (_) => true);
    const {setItems} = useContext(CartCounterContext);
    const filter = useSelector((state) => state.paints.filter);
    const pagination = useSelector((state) => state.parts.pagination);


    const {
        loading: componentsLoading,
        data: components,
    } = useQuery(PAINTS_QUERY, {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
        onError: (error => {
            notifications.error(`Errore nel server: ${error.message}`)
        }),
    });


    useEffect(() => {
        if (filter.fullText && filter.fullText !== '')
            setFilterFn(() => (item) => {
                return item.article.toLowerCase().includes(filter.fullText.toLowerCase());
            })

    }, []);

    const columns = [
        {
            dataIndex: 'productImage',
            key: 'productImage',
            render: (text) => <Image
                src={text}
                width={50}
                fallback={placeholder}
                onClick={(e) => {
                    e.stopPropagation()
                }}
            />,
        },
        {
            title: 'Codice',
            dataIndex: 'article',
            key: 'article',
        },
        {
            title: 'Nome',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Brand',
            dataIndex: 'producerName',
            key: 'producerName',
            filters: components?.paints ? Object.keys(components?.paints.reduce((acc, item) => {
                acc[item.producerName] = '';
                return acc;
            }, {})).map((item) => {
                return {
                    text: item,
                    value: item
                }
            }) : [],
            onFilter: (value, record) => record.producerName === value,
        },
        {
            title: (<div>Disponibilità</div>),
            dataIndex: 'availableQuantity',
            key: 'availableQuantity',
            sorter: ({availableQuantity : a}, {availableQuantity : b}) => {
                return parseFloat(a) > parseFloat(b) ? 1 : -1
            },
            sortDirections: ['ascend', 'descend'],
            defaultSortOrder: 'descend',
            render: (_, record) => {
                return record.availableQuantity ? record.availableQuantity : 0
            }
        },
        {
            title: 'In arrivo',
            key: 'orderedQuantity',
            sorter: true,
            render: (_, record) => {
                return record.orderedQuantity ? record.orderedQuantity : 0
            }
        },
        {
            title: 'Prezzo',
            key: 'price',
            sorter: true,
            render: (_, record) => {
                if (!record.price)
                    return <Text>N/D</Text>

                const basePrice = beautifyNumber(record.price);
                if (showDiscount && getDiscount('vernici', record.producerName)) {
                    const priceDiscount = Math.round(record.price * (100 - getDiscount('accessori', record.producerName))) / 100;
                    return <Flex gap={"middle"}>
                        <Text>
                            <del>{basePrice} €</del>
                        </Text>
                        <Text strong>{beautifyNumber(priceDiscount)} €</Text>
                    </Flex>
                }
                return <Text strong>
                    {basePrice} €
                </Text>


            }
        },
    ];


    return <Flex vertical gap={'large'}>
        <Row align={'middle'}>
            <Col span={12}>
                <Title level={3}>Vernici Raptor</Title>
            </Col>
        </Row>
        <GeneralFilterForm
            onFinish={setFilterFn}
            filter={filter}
            setFilter={setFilter}
        />
        <GeneralTable
            data={components?.paints.filter(filterFn)}
            dataLoading={componentsLoading}
            handleShowArticle={(article) => {
                setSelectedArticle(article);
                setVisible(true);
            }}
            columns={columns}
            pagination={pagination}
            setTablePagination={setTablePagination}
            setSort={setSort}
        />
        <Drawer
            title={`${selectedArticle?.article}`}
            size={'large'}
            onClose={() => {
                setVisible(false)
                setSelectedArticle({})
                setSelectedArticleQuantity([
                    {
                        key: 'availableQuantity', label: 'Disponibilità immediata', overprice: 0, quantity: 0
                    }
                ])
            }}
            open={visible}>
            <GeneralDetail
                category={'vernici'}
                selectedArticle={selectedArticle}
                setItems={setItems}
                setSelectedArticleQuantity={(value) => {
                    setSelectedArticleQuantity(value)
                }}
                articleDetailVoices={{
                    "producerName": "Brand",
                    "description": "Descrizione",
                    "boxQuantity": "Quantità per scatola",
                }}
                selectedArticleQuantity={selectedArticleQuantity}
                setVisible={setVisible}
                showDiscount={showDiscount}
                notes={<Alert
                    message={"Sconti applicati secondo scaglioni sul totale: 0-750€ 25%, 750-1500€ 30% e 1500+ € 35% "}/>}
            />
        </Drawer>
    </Flex>
}