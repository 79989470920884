import {createContext} from 'react'
import {message} from "antd";

export const NotificationsContext = createContext()
export const NotificationsProvider = ({children}) => {
    const [notifications, contextHolder] = message.useMessage();


    return <NotificationsContext.Provider value={{
        notifications
    }}>
        {contextHolder}
        {children}
    </NotificationsContext.Provider>
}