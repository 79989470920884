import {Button, Form, Input, Select} from "antd";
import {useEffect} from "react";

export const EditForm = ({updateFn, loadingUpdate, item}) => {
    const [form] = Form.useForm();
    useEffect(() => {
        form.setFieldsValue({
            trackingID: item.trackingID,
            status: item.status,
        })
    }, [form, item.status, item.trackingID]);

    return <Form
        layout={"vertical"}
        initialValues={{
            trackingID: '',
            status: '',
        }}
        form={form}
        onFinish={(values) => {
            updateFn(values)
        }}
    >
        <Form.Item label="Tracking link" name={'trackingID'}>
            <Input placeholder="link"/>
        </Form.Item>
        <Form.Item label="Stato" name={'status'}>
            <Select showSearch
                    options={[
                        {
                            value: 0,
                            label: 'Non spedito',
                        },
                        {
                            value: 1,
                            label: 'Spedito',
                        },
                        {
                            value: 2,
                            label: 'Annullato',
                        }
                    ]}
            />
        </Form.Item>
        <Form.Item>
            <Button type="primary" htmlType={"submit"} loading={loadingUpdate}>Fatto</Button>
        </Form.Item>
    </Form>
}