import {Alert, Button, Form, Input, Row, Select, Spin, Switch, Typography, Upload} from "antd";
import React, {useContext, useState} from "react";
import {MAPPINGS_QUERY} from "../apollo/apollo-queries";
import {useQuery} from "@apollo/client";
import {UploadOutlined} from "@ant-design/icons";
import {useAuth0} from "@auth0/auth0-react";
import axios from "axios";
import {NotificationsContext} from "../components/common/NotificationsContext";

export const {Title, Text} = Typography;

export const NewCatalog = () => {
    const {notifications} = useContext(NotificationsContext)

    const [fileToUpload, setFileToUpload] = useState();
    const [selectedType, setSelectedType] = useState("");
    const [brandFilter, setBrandFilter] = useState("");
    const [pipeDelimited, setPipeDelimited] = useState(true);
    const [selectedMapping, setSelectedMapping] = useState("");
    const [progress, setProgress] = useState(0);
    const [uploading, setUploading] = useState(false);

    const {getAccessTokenSilently} = useAuth0();

    const {
        data: mappings, refetch: refetchMappings
    } = useQuery(MAPPINGS_QUERY, {
        errorPolicy: "all",
        onError: error => notifications.error(`Errore nel server: ${error.message}`),
    });

    const handleFileToUploadChange = (event) => {
        setFileToUpload(event.target.files[0]);
    }

    const handleUpload = async (event) => {

        const token = await getAccessTokenSilently();

        setUploading(true)
        event.preventDefault();
        const headers = new Headers()
        headers.append("authorization", token)
        headers.append("Content-Type", "multipart/form-data");
        headers.append('Content-Disposition', 'form-data');

        const data = new FormData();
        data.append('file', fileToUpload, fileToUpload.name);

        axios.post(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_ADDRESS}/catalog?mappingName=${selectedMapping}&docType=${selectedType}&pipeDelimited=${pipeDelimited}&brandFilter=${brandFilter}`, data, {
            onUploadProgress: ProgressEvent => {
                setProgress(Math.round(ProgressEvent.loaded / ProgressEvent.total * 100));
            }
        }, {
            headers: headers
        })
            .then((res) => {
                if (res.status !== 200) throw new Error(res.data.message()); else return res;
            })
            .catch((errors) => {
                if (errors.code === "ERR_BAD_RESPONSE")
                    console.log(errors)
                else
                    console.log(errors)


            })
            .finally(() => {
                setPipeDelimited(false);
                setSelectedMapping("");
                setSelectedMapping("");
                setUploading(false);
            });

    };

    return <Form layout="vertical">
        <Form.Item label="Scegli file" name="file" className="mb-3 mt-3">
            <Upload beforeUpload={(file) => {
                handleFileToUploadChange({target: {files: [file]}});
                return false;
            }}>
                <Button icon={<UploadOutlined/>}>Select File</Button>
            </Upload>
        </Form.Item>

        <Form.Item label="Seleziona tipo file" name="type" className="mb-3">
            <Select showSearch onChange={setSelectedType} value={selectedType} options={[
                {key: "option-fitment", value: "fitment", label: "Fitment"},
                {key: "option-component", value: "component", label: "Componente"}
            ]}/>
        </Form.Item>

        {selectedType === "fitment" && (
            <Form.Item label="Filtro brand" name="brandFilter" className="mb-3">
                <Input
                    type="text"
                    placeholder="Es. BMW, Jeep, ....."
                    onChange={(e) => setBrandFilter(e.target.value)}
                    value={brandFilter}
                    autoComplete="off"
                />
            </Form.Item>
        )}

        <Form.Item label="Seleziona mappatura" name="mapping" className="mb-3">
            <Select
                showSearch
                onChange={setSelectedMapping}
                value={selectedMapping}
                disabled={!selectedType}
                options={mappings?.getMappings
                    .filter(({type}) => type === selectedType)
                    .map(({name}, index) => {
                        return {value: name, key: index, label: name}
                    })
                }
            >
            </Select>
        </Form.Item>

        <Form.Item label="Pipe delimited" name="pipeDelimited" valuePropName="checked">
            <Switch checked={pipeDelimited} onChange={setPipeDelimited}/>
        </Form.Item>

        <Row justify={"end"}>
            <Button
                type="primary"
                onClick={handleUpload}
                disabled={!fileToUpload || !selectedType || !selectedMapping}
            >
                Upload
            </Button>
        </Row>

        <Row justify="center" className="text-center mt-2">
            {uploading && (
                progress !== 100 ? (
                    <Alert type="warning" showIcon message={`Upload in corso... ${progress}%`} icon={<Spin/>}/>
                ) : (
                    <Alert type="warning" showIcon
                           message={`Parsing e salvataggio in corso... Il processo potrebbe richiedere qualche minuto.`}
                           icon={<Spin/>}/>
                )
            )}
        </Row>
    </Form>


}