import { createSlice } from '@reduxjs/toolkit';

export const cartSlice = createSlice({
    name: 'cart',
    initialState: {
        items: [],
        selectedCustomer: null,
    },
    reducers: {
        setItems: (state, action) => {
            state.items = action.payload;
        },
        addItem: (state, action) => {
            state.items.push(action.payload);
        },
        removeItem: (state, action) => {
            state.items = state.items.filter((_, index) => index !== action.payload);
        },
        setSelectedCustomer: (state, action) => {
            state.selectedCustomer = action.payload;
        },
        clearCart: (state) => {
            state.items = [];
        },
    },
});

export const { setItems, addItem, removeItem, setSelectedCustomer, clearCart } = cartSlice.actions;

export default cartSlice.reducer;
