import {Button, Checkbox, Col, Flex, Form, Input, Row, Tag,} from "antd";
import React, {Fragment, useContext, useEffect, useState} from "react";
import {Title} from "./Home";
import {SearchOutlined} from "@ant-design/icons";
import {OrdersTableCustomer} from "../components/orders/OrdersTableCustomer";
import {OrdersTableAdmin} from "../components/orders/OrdersTableAdmin";
import {useAuth0} from "@auth0/auth0-react";
import {NewOrdersCounterContext} from "../components/orders/NewOrdersCounterContext";


export const Orders = () => {
    const {orders, ordersLoading, refetch} = useContext(NewOrdersCounterContext)

    const [searchValue, setSearchValue] = useState({text: '', status: []})
    // L'idea e' quella di avere per ogni filtro direttamente la funzione di comparazione
    const [filter, setFilter] = useState({
        id: (id) => id.includes(''),
        status: (status) => status >= 0
    })
    const {user} = useAuth0();

    function getStatusTag(item) {
        switch (item) {
            case 0:
                return <Tag color={"green"}>Ricevuto</Tag>
            case 1:
                return <Tag color={"orange"}>Approvato</Tag>
            case 2:
                return <Tag color={"volcano"}>In arrivo</Tag>
            case 3:
                return <Tag color={"blue"}>Concluso</Tag>
            case 4:
                return <Tag color={"cyan"}>Spedito parziale</Tag>
            case 5:
                return <Tag color={"red"}>Annullato</Tag>
            default:
                return <Fragment/>
        }

    }

    const [form] = Form.useForm();

    useEffect(() => {
        if (user && user["https://magnus-store.com/roles"].includes('admin')) {
            setFilter({
                id: (id) => id.includes(''),
                status: () => true,
                customer: (customer) => customer.includes('')
            })
        }
    }, [user]);

    const filterFn = (item) => {
        return filter.status(item.status) &&
            (filter.customer ? filter.id(item.id) || filter.customer(item.customer) : filter.id(item.id));
    }

    return <Flex vertical gap={'large'} className={'h-100'}>
        <Flex justify={"start"}>
            <Title level={3}>I tuoi ordini</Title>
        </Flex>
        <Form
            layout={"vertical"}
            form={form}
            onFinish={(values) => {
                setFilter((prevState) => {
                    const newFilters = {...prevState};
                    if (values.id) {
                        newFilters.id = (id) => {
                            return id.includes(values.id);
                        }
                        if (user && user["https://magnus-store.com/roles"].includes('admin')) {
                            newFilters.customer = (customer) => {
                                return customer.includes(values.id);
                            }
                        }
                    }
                    if (values.status) {
                        newFilters.status = (status) => {
                            return values.status.includes(status);
                        }
                    }
                    return newFilters;
                })
            }}
            onReset={() => {
                if (user && user["https://magnus-store.com/roles"].includes('admin')) {
                    setFilter({
                        id: (id) => id.includes(''),
                        status: (status) => status >= 0,
                        customer: (customer) => customer.includes('')
                    })
                } else {
                    setFilter({
                        id: (id) => id.includes(''),
                        status: (status) => status >= 0
                    })
                }
                form.resetFields()
            }}
            className="filter-form"
        >
            <Form.Item name={'id'}>
                <Input
                    placeholder={`Trova per id ordine ${user && user["https://magnus-store.com/roles"].includes('admin') ? 'o per cliente' : ''}`}
                    prefix={<SearchOutlined/>}/>
            </Form.Item>
            <Form.Item label="Ricerca per stato ordine" name={'status'}>
                <Checkbox.Group>
                    {[0, 1, 2, 3, 4, 5].map((status) => <Checkbox key={status}
                                                               value={status}>{getStatusTag(status)}</Checkbox>)}
                </Checkbox.Group>
            </Form.Item>
            <Row align={"middle"}>
                <Col span={12}>
                    <Flex justify={"start"}>
                        <Form.Item>
                            <Button type="primary" htmlType={"submit"}>Fatto</Button>
                        </Form.Item>
                    </Flex>
                </Col>
                <Col span={12}>
                    <Flex justify={"end"}>
                        <Form.Item>
                            <Button type="default" htmlType={"reset"}>Reset filtri</Button>
                        </Form.Item>
                    </Flex>
                </Col>
            </Row>
        </Form>
        {user && orders && !user["https://magnus-store.com/roles"].includes('admin') &&
            <OrdersTableCustomer
                loading={ordersLoading}
                getStatusTag={getStatusTag}
                searchValue={searchValue}
                orders={orders?.orders?.filter(filterFn)}
            ></OrdersTableCustomer>}
        {user && orders && user["https://magnus-store.com/roles"].includes('admin') &&
            <OrdersTableAdmin
                loading={ordersLoading}
                getStatusTag={getStatusTag}
                searchValue={searchValue}
                orders={orders?.orders?.filter(filterFn)}
            ></OrdersTableAdmin>}
    </Flex>
}