import {Flex, Row, Steps, Table} from "antd";
import {Text} from "../../views/Home";
import React, {useContext, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import moment from "moment";
import {beautifyNumber} from "../common/utils";
import {useLazyQuery} from "@apollo/client";
import {CUSTOMER_QUERY} from "../../apollo/apollo-queries";
import {NotificationsContext} from "../common/NotificationsContext";

export const CustomerTable = ({order}) => {
    const {notifications} = useContext(NotificationsContext)
    const [address, setAddress] = useState('')
    const orderStateColumns = [
        {
            title: 'ID ordine',
            dataIndex: 'id',
        },
        {
            title: 'Data ordine',
            dataIndex: 'orderDate',
        },
        {
            title: 'Tracking',
            dataIndex: 'trackingID',
            render: (item) => {
                return item && item !== '' ? <a href={item} target="_blank" rel="noopener noreferrer">Vedi il tracking</a> : 'non disponibile'
            }
        },
        {
            title: 'Data consegna',
            dataIndex: 'deliveryDate',
            render: (item) => {
                return moment.unix(parseFloat(item) / 1000).format("YYYY-MM-DD")
            }
        },
        {
            title: (<div>Totale <br/> (iva escl.)</div>),
            dataIndex: 'total',
            render: (item) => `${beautifyNumber(item)} €`
        }
    ];
    function getStatus(item) {
        switch (item) {
            case 0:
                return 0
            case 1:
                return 1
            case 2:
                return 2
            case 3:
                return 3
            case 4:
                return 2
            default:
                return 0
        }

    }

    const [fetchAccount] = useLazyQuery(CUSTOMER_QUERY, {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
        onError: error => {
            notifications.error(`Errore nel server: ${error.message}`)
        },
        onCompleted: (data) => {
            setAddress(`${data.customer.street} ${data.customer.postalCode}, ${data.customer.city}, ${data.customer.state}`)
        }
    });

    useEffect(() => {
        fetchAccount({variables: {mail: order.customer}})
    }, [fetchAccount, order.customer]);

    return <Flex vertical={true} gap={"middle"}>
        <Table
            dataSource={[order]}
            columns={orderStateColumns}
            pagination={{position: ["none", "none"]}}
            rowKey={(record) => record.title}
        >
        </Table>
        <Text strong={true}>Indirizzo spedizione:</Text>
        <Row justify={"start"}>
            {order.alternativeAddress ? order.alternativeAddress : address}
        </Row>
        <Text strong={true}>Stato ordine:</Text>
        <Row>
            <Steps
                size="small"
                current={getStatus(order.status)}
                items={[
                    {
                        title: 'Ricevuto',
                    },
                    {
                        title: 'Approvato',
                    },
                    {
                        title: 'In lavorazione',
                    },
                    {
                        title: 'Completato',
                    },
                ]}
            />
        </Row>
    </Flex>
}
