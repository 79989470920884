import CustomerDiscountTable from "./CustomerDiscountTable";
import {useQuery} from "@apollo/client";
import {GET_CUSTOMER_DISCOUNT} from "../../apollo/apollo-queries";
import {useContext, useState} from "react";
import {NotificationsContext} from "../common/NotificationsContext";

export const CustomerDiscount = () => {
    const {notifications} = useContext(NotificationsContext)

    const {
        data: customerDiscounts, refetch: refetchCustomerDiscounts, loading: customerDiscountsLoading
    } = useQuery(GET_CUSTOMER_DISCOUNT, {
        errorPolicy: "all",
        onCompleted: data => {
            setCustomers(
                Object.keys(
                    data.getCustomerDiscounts.reduce((acc, item) => {
                        acc[item.user] = '';
                        return acc;
                    }, {})
                )
            )
        },
        onError: error => notifications.error('Impossibile caricare i clienti.')
    });
    const [customers, setCustomers] = useState([])

    return <CustomerDiscountTable
        customers={customers}
        discounts={customerDiscounts}
        refetch={refetchCustomerDiscounts}
        loading={customerDiscountsLoading}
    />
}