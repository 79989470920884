import React, {useContext} from 'react';
import {Button, Form, Input, Select} from 'antd';
import {LockOutlined, UserOutlined} from '@ant-design/icons';
import {useMutation} from "@apollo/client";
import {ADD_CUSTOMER} from "../../apollo/apollo-queries";
import {NotificationsContext} from "../common/NotificationsContext";

const RegistrationForm = ({refetch, roles}) => {
    const {notifications} = useContext(NotificationsContext)
    const [addCustomer, {
        loading: addUserLoading,
        error: addUserError
    }] = useMutation(ADD_CUSTOMER, {
        onCompleted: async () => {
            notifications.success('Utente aggiunto con successo!');
            await refetch();
        },
        onError: (error => {
            notifications.error(`Errore nel server: ${error.message}`)
        })
    });

    const [form] = Form.useForm();

    const onFinish = async (value) => {
        if (!addUserError)
            await addCustomer({
                variables: {
                    customer: {email: value.user.email, businessName: value.user.name},
                    password: value.user.password,
                    roles: value.roles
                }
            });
        form.resetFields();
    };

    return (
        <Form
            form={form}
            name="modulo_registrazione"
            className="modulo-registrazione mt-5"
            initialValues={{remember: true}}
            onFinish={onFinish}
        >
            {/* Email */}
            <Form.Item
                name={['user', 'email']}
                rules={[
                    {required: true, message: 'Inserisci il tuo indirizzo email!'},
                    {type: 'email', message: 'Inserisci un indirizzo email valido!'},
                ]}
            >
                <Input prefix={<UserOutlined className="site-form-item-icon"/>} placeholder="Email"/>
            </Form.Item>

            {/* Nome */}
            <Form.Item
                name={['user', 'name']}
                rules={[{required: true, message: 'Inserisci il tuo nome!'}]}
            >
                <Input placeholder="Nome"/>
            </Form.Item>

            {/* Password */}
            <Form.Item
                name={['user', 'password']}
                rules={[
                    {required: true, message: 'Inserisci la tua password!'},
                    {
                        pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
                        message: 'La password deve contenere almeno una lettera minuscola, una maiuscola e un numero.',
                    },
                ]}
            >
                <Input
                    prefix={<LockOutlined className="site-form-item-icon"/>}
                    type="password"
                    placeholder="Password"
                />
            </Form.Item>

            {/* roles */}
            <Form.Item name="roles">
                <Select
                    showSearch
                    mode={'tags'}
                    placeholder={'Seleziona ruolo'}
                    options={roles ? roles.map(role => {
                        return {value: role.name, label: role.description, key: role.name}
                    }) : []}
                ></Select>
            </Form.Item>

            {/* Pulsante di Invio */}
            <Form.Item>
                <Button type="primary" htmlType="submit" loading={addUserLoading}>
                    Registra utente
                </Button>
            </Form.Item>
        </Form>
    );
};

export default RegistrationForm;

