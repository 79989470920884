import React, {useContext, useEffect, useState} from 'react';
import {Button, Form, Input, Select} from 'antd';
import {useMutation, useQuery} from "@apollo/client";
import {
    ADD_CUSTOMER_DISCOUNT, BRANDS_CATEGORY,
    GET_USERS,
} from "../../apollo/apollo-queries";
import {NotificationsContext} from "../common/NotificationsContext";

const {Option} = Select;

const RegistrationForm = ({refetch, discounts = []}) => {
    const {notifications} = useContext(NotificationsContext);
    const [addDiscount, {
        loading: addDiscountLoading
    }] = useMutation(ADD_CUSTOMER_DISCOUNT, {
        onCompleted: async (data) => {
            await refetch();
        },
        onError: (error => {
            notifications.error('Errore durante il caricamento degli sconti');
        })
    });
    const {
        data: users,
        loading: usersLoading
    } = useQuery(GET_USERS, {
        errorPolicy: "all",
        onCompleted: async (data) => {
        },
        onError: (error => {
            notifications.error('Errore durante il caricamento degli utenti ');
        })
    });
    const {
        data: brands,
        loading: brandsLoading
    } = useQuery(BRANDS_CATEGORY, {
        errorPolicy: "all",
        onCompleted: async (data) => {
        },
        onError: (error => {
            notifications.error('Errore durante il caricamento dei fornitori');
        })
    });

    const [form] = Form.useForm();
    const [target, setTarget] = useState(null);
    const [user, setUser] = useState(null);
    const [targetBrands, setTargetBrands] = useState([]);

    const onFinish = async (value) => {
        let brand;
        if (value.brand.includes("all")) {
            brand = brands?.brands.filter(({category}) => category === target).map(({name}) => name);
        } else {
            brand = value.brand
        }
        await addDiscount({
            variables: {discount: {...value, discount: parseFloat(value.discount), brand}}
        })
    };

    useEffect(() => {
        if (target === 'accessori') {
            setTargetBrands(brands?.brands.filter(({category}) => category === target).map(({name}) => name));
        }
        if (target === 'cerchi') {
            setTargetBrands(brands?.brands.filter(({category}) => category === target).map(({name}) => name));
        }
        if (target === 'lubrificanti') {
            setTargetBrands(brands?.brands.filter(({category}) => category === target).map(({name}) => name));
        }
        if (target === 'ricambi') {
            setTargetBrands(brands?.brands.filter(({category}) => category === target).map(({name}) => name));
        }
        if (target === 'vernici') {
            setTargetBrands(brands?.brands.filter(({category}) => category === target).map(({name}) => name));
        }
    }, [user, target, brands?.brands])

    return (
        <Form
            form={form}
            name="modulo_registrazione"
            className="modulo-registrazione mt-5"
            initialValues={{remember: true}}
            onFinish={onFinish}
        >
            {/* Email */}
            <Form.Item
                name={'user'}
                rules={[
                    {required: true, message: 'Seleziona l\'utente!'},
                ]}
            >
                <Select
                    showSearch
                    placeholder="Seleziona un'utente"
                    onChange={(value) => setUser(value)}
                    loading={usersLoading}
                >
                    {users?.getUsers && users.getUsers.map((user, idx) => <Option key={idx}
                                                                                  value={user}>{user}</Option>)}
                </Select>
            </Form.Item>

            {/* Nome */}
            <Form.Item
                name={'target'}
                rules={[{required: true, message: 'Seleziona categoria!'}]}

            >
                <Select showSearch placeholder="Seleziona categoria" onChange={(value) => setTarget(value)}>
                    <Option key="accessori" value={'accessori'}>Accessori</Option>
                    <Option key="cerchi" value={'cerchi'}>Cerchi</Option>
                    <Option key="lubrificanti" value={'lubrificanti'}>Lubrificanti</Option>
                    <Option key="ricambi" value={'ricambi'}>Ricambi</Option>
                    <Option key="vernici" value={'vernici'}>Vernici</Option>
                </Select>
            </Form.Item>

            {/* Nickname */}
            <Form.Item
                name={'brand'}
                rules={[{required: true, message: 'Seleziona il brand!'}]}
            >
                <Select showSearch placeholder="Seleziona Brand" mode="multiple" loading={brandsLoading}>
                    <Option
                        disabled={form.getFieldValue('user') === undefined || discounts.filter(discount => discount.user === form.getFieldValue('user') && discount.target === target)?.length !== 0}
                        key={'all'} value={"all"}>Tutti i brand</Option>
                    {
                        targetBrands.map((item) => {
                            return <Option
                                disabled={discounts.find(discount => discount.brand === item && discount.user === user)}
                                key={item}
                                value={item}>{item}</Option>
                        })
                    }
                </Select>
            </Form.Item>
            {/* Password */}
            <Form.Item
                name={'discount'}
                rules={[
                    {required: true, message: 'Inserisci lo sconto!'},
                    {
                        pattern: /^[0-9]+(.[0-9]+)?$/,
                        message: 'Lo sconto deve essere un numero.',
                    },
                ]}
            >
                <Input
                    type="input"
                    placeholder="Sconto"
                />
            </Form.Item>

            {/* Pulsante di Invio */}
            <Form.Item>
                <Button type="primary" htmlType="submit" loading={addDiscountLoading}>
                    Registra sconto
                </Button>
            </Form.Item>
        </Form>
    );
};

export default RegistrationForm;

