import React, {useContext} from 'react';
import {Flex, Image, Table} from 'antd';
import placeholder from "../../assets/placeholder.jpg";
import {DiscountsContext} from "../common/DiscountContext";
import {beautifyNumber} from "../common/utils";
import {Text} from "../../views/Home";
import {useDispatch, useSelector} from "react-redux";

const WheelsTable = ({
                         wheelsLoading,
                         handleTableChange,
                         wheels,
                         handleShowArticle,
                         showDiscount
                     }) => {
    const {getDiscount} = useContext(DiscountsContext);
    const pagination = useSelector((state) => state.wheels.pagination);
    const filter = useSelector((state) => state.wheels.filter);

    const columns = [
            {
                dataIndex: 'ImageURL',
                key: 'ImageURL',
                render: (text, _) => <Image
                    src={text}
                    fallback={placeholder}
                    width={50}
                    onClick={(e) => {
                        e.stopPropagation()
                    }}
                />,
            },
            {
                title: 'Codice',
                dataIndex: 'PartNumber',
                key: 'PartNumber',
            },
            {
                title: 'Descrizione',
                dataIndex: 'PartDescription',
                key: 'PartDescription',
            },
            {
                title: 'Brand',
                dataIndex: 'Brand',
                key: 'Brand',
            },
            {
                title: 'Misura',
                dataIndex: 'Size',
                key: 'Size',
            },
            {
                title: (<div>Disponibilità <br/> US</div>),
                dataIndex: 'AmericaQOH',
                key: 'AmericaQOH',
                sorter: true,
                sortDirections: ['descend', 'ascend', 'descend'],
                sortOrder: filter.sort.key === 'AmericaQOH' ? filter.sort.order === 1 ? 'ascend' : 'descend' : null,
            },
            {
                title: (<div>Disponibilità <br/> 4-5gg</div>),
                dataIndex: 'EuropeQOH',
                key: 'EuropeQOH',
                sorter: true,
                sortDirections: ['descend', 'ascend', 'descend'],
                sortOrder: filter.sort.key === 'EuropeQOH' ? filter.sort.order === 1 ? 'ascend' : 'descend' : null,
            },
            {
                title: (<div>Disponibilità <br/> 24/48h</div>),
                dataIndex: 'InternalQOH',
                key: 'InternalQOH',
                sorter: true,
                sortDirections: ['descend', 'ascend', 'descend'],
                sortOrder: filter.sort.key === 'InternalQOH' ? filter.sort.order === 1 ? 'ascend' : 'descend' : null,

            },
            {
                title: <Flex justify={"center"}>Prezzo</Flex>,
                key:
                    'price',
                render:
                    (_, record) => {
                        const basePrice = beautifyNumber(record.MSRP_EUR);
                        if (showDiscount && getDiscount('cerchi', record.Brand)) {
                            const priceDiscount = Math.round(record.MSRP_EUR * (100 - getDiscount('cerchi', record.Brand))) / 100;
                            return <Flex gap={"middle"}>
                                <Text>
                                    <del>{basePrice} €</del>
                                </Text>
                                <Text strong>{beautifyNumber(priceDiscount)} €</Text>
                            </Flex>
                        }
                        return <Text strong>
                            {basePrice} €
                        </Text>
                    }
            }
        ]
    ;

    return <div>
        <Table
            dataSource={wheels?.getWheels?.wheels.map((item, idx) => {
                return {...item, key: idx}
            })}
            columns={columns}
            loading={wheelsLoading}
            rowHoverable={false}
            rowClassName={'wheelTableRow'}
            pagination={pagination}
            onChange={handleTableChange}
            size={"small"}
            onRow={(record) => {
                return {
                    onClick: (event) => {
                        if (event.target.tagName === 'TD')
                            handleShowArticle(record)
                    }
                }
            }}
        />
    </div>;
};

export default WheelsTable;
