import {Button, Flex, Input, Row, Space, Table, Typography} from "antd";
import React, {useContext, useRef, useState} from "react";
import {REFRESH_WHEELS, STOCK_QUERY} from "../apollo/apollo-queries";
import {useLazyQuery, useQuery} from "@apollo/client";
import {beautifyNumber, getFormattedDate} from "../components/common/utils";
import FilePicker from "../components/common/FilePicker";
import {SearchOutlined} from "@ant-design/icons";
import Highlighter from 'react-highlight-words';
import {NotificationsContext} from "../components/common/NotificationsContext";

export const {Title, Text} = Typography;

export const Stock = () => {
    const {notifications} = useContext(NotificationsContext)
    const [stockLastUpdate, setStockLastUpdate] = useState();
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [loading, setLoading] = useState(false)
    const {
        data: stock,
        loading: stockLoading,
    } = useQuery(STOCK_QUERY, {
        variables: {limit: 25, offset: 0}, fetchPolicy: "no-cache", errorPolicy: "all",
        onCompleted: (data) => {
            const lu = getFormattedDate(parseInt(data.getStock.sort((a, b) => parseInt(a.lastUpdate) < parseInt(b.lastUpdate) ? 1 : -1)[0].lastUpdate))

            setStockLastUpdate(lu)
        }, onError: (error => {
            notifications.error(`Errore nel server: ${error.message}`)
        })
    });

    const [refreshWheels, {
        loading: rwLoading
    }] = useLazyQuery(REFRESH_WHEELS, {
        errorPolicy: "all",
        fetchPolicy: "no-cache",
        onCompleted: () => notifications.success("Magazzino cerchi aggiurnato con successo."),
        onError: (error => {
            notifications.error(`Errore nel server: ${error.message}`)
        })
    });

    const searchInput = useRef(null);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {

        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters, close}) => (<div
            style={{
                padding: 8,
            }}
            onKeyDown={(e) => e.stopPropagation()}
        >
            <Input
                ref={searchInput}
                placeholder={`Search ${dataIndex}`}
                value={selectedKeys[0]}
                onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                style={{
                    marginBottom: 8, display: 'block',
                }}
            />
            <Space>
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined/>}
                    size="small"
                    style={{
                        width: 90,
                    }}
                >
                    Search
                </Button>
                <Button
                    onClick={() => {
                        clearFilters && handleReset(clearFilters)
                        handleSearch('', confirm, dataIndex)
                    }}
                    size="small"
                    style={{
                        width: 90,
                    }}
                >
                    Reset
                </Button>
                <Button
                    type="link"
                    size="small"
                    onClick={() => {
                        confirm({
                            closeDropdown: false,
                        });
                        setSearchText(selectedKeys[0]);
                        setSearchedColumn(dataIndex);
                    }}
                >
                    Filter
                </Button>
                <Button
                    type="link"
                    size="small"
                    onClick={() => {
                        close();
                    }}
                >
                    close
                </Button>
            </Space>
        </div>),
        filterIcon: (filtered) => (<SearchOutlined
            style={{
                color: filtered ? '#1890ff' : undefined,
            }}
        />),
        onFilter: (value, record) => {
            return record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : false
        },
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => {
            if (searchedColumn === dataIndex) {
                return <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069', padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            } else return text;

        },
    });

    const stockColumns = [{
        title: 'Articolo',
        dataIndex: 'article',
        sorter: (a, b) => a.article > b.article ? 1 : -1,
        ...getColumnSearchProps('article'),
        sortDirections: ['descend'],
    }, {
        title: 'Descrizione',
        dataIndex: 'description',
        ...getColumnSearchProps('description')
    }, {
        title: 'Produttore',
        filters: stock?.getStock ? [...new Set(stock?.getStock.map(({producerName}) => producerName))]
            .sort((a, b) => a > b ? 1 : -1)
            .map(key => {
                return {
                    text: key.toUpperCase(),
                    value: key,
                }
            }) : [],
        filterMode: 'tree',
        filterSearch: true,
        onFilter: (value, record) => record.producerName.startsWith(value),
        dataIndex: 'producerName',
    }, {
        title: 'Q.ta disponibile',
        dataIndex: 'availableQuantity',
        sorter: (a, b) => a.availableQuantity - b.availableQuantity,
        sortDirections: ['descend', 'ascend'],
    }, {
        title: 'In arrivo',
        dataIndex: 'orderedQuantity',
        sorter: (a, b) => a.orderedQuantity - b.orderedQuantity,
        sortDirections: ['descend', 'ascend'],
    }, {
        title: 'Prezzo',
        dataIndex: 'price',
        sorter: (a, b) => a.price - b.price,
        sortDirections: ['descend', 'ascend'],
        render: (text) => `${beautifyNumber(text)} €`
    }];

    const handleWarehouseSubmit = async (file) => {
        const data = new FormData();
        if (file) {
            data.append("file", file);
        }
        setLoading(true);
        await fetch(`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_ADDRESS}/stock`, {
            method: 'POST',
            body: data,
        })
            .then((res) => {
                if (res.ok)
                    return res.json()
                throw new Error('Magazzino non aggiornato')
            })
            .then(() => notifications.success("Magazzino aggiurnato con successo."))
            .catch((err) => notifications.error(err.message))
            .finally(() => setLoading(false));
    };


    return <Flex vertical gap={"large"}>
        <Flex vertical={true}>
            <Title level={3}>Storico</Title>
            <Title level={5}>Ultimo aggiornamento: {stockLastUpdate}</Title>
        </Flex>
        <Row
            justify={"end"}
        >
            <FilePicker handleFileSelection={handleWarehouseSubmit}/>
            <Button className={'ms-3'} type={"primary"} onClick={refreshWheels} loading={rwLoading}> Refresh
                cerchi </Button>
        </Row>
        <Table
            size={"small"}
            dataSource={stock?.getStock}
            columns={stockColumns}
            loading={stockLoading || loading}
            pagination={{pageSize: 10}}
            rowKey={(record) => record.id}/>
    </Flex>

}
