import {createContext, useContext, useEffect, useState} from 'react'
import {DiscountsContext} from "../common/DiscountContext";

export const CartCounterContext = createContext()
export const CartCounterContextProvider = ({children}) => {
    const {getUserDiscount, getPaintDiscount} = useContext(DiscountsContext);
    const [items, setItems] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState();

    useEffect(() => {
        const sessionCart = sessionStorage.getItem('cart');
        if (sessionCart) {
            setItems(JSON.parse(sessionCart));
        }
    }, []);

    useEffect(() => {
        sessionStorage.setItem('cart', JSON.stringify(items));
    }, [items])

    useEffect(() => {
        if (selectedCustomer) {
            setItems((prev) => {
                return prev.map(item => {
                    if (item.category !== 'vernici')
                        item.unitPrice.discount = getUserDiscount(selectedCustomer, item.category, item.brand);
                    else
                        item.unitPrice.discount = getPaintDiscount(item.quantity * item.unitPrice.base);

                    item.unitPrice = Math.round(item.unitPrice.base * (100 - item.unitPrice.discount)) / 100
                    return item
                })
            });
        }
    }, [getPaintDiscount, getUserDiscount, selectedCustomer, setItems]);

    return <CartCounterContext.Provider value={{
        items,
        setItems,
        selectedCustomer,
        setSelectedCustomer
    }}>
        {children}
    </CartCounterContext.Provider>
}