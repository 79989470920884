import {Col, Drawer, Flex, Image, Row, Switch,} from "antd";
import PartsForm from "../components/accessories/AccessoryFilters";
import {useLazyQuery} from "@apollo/client";
import {COMPONENTS_QUERY} from "../apollo/apollo-queries";
import React, {useContext, useState} from "react";
import {Text, Title} from "./Home";
import {CartCounterContext} from "../components/cart/CartCounterContext";
import AccessoryTable from "../components/accessories/AccessoryTable";
import {AccessoryDetail} from "../components/accessories/AccessoryDetail";
import {NotificationsContext} from "../components/common/NotificationsContext";
import placeholder from "../assets/placeholder.jpg";
import {beautifyNumber} from "../components/common/utils";
import {DiscountsContext} from "../components/common/DiscountContext";
import {useDispatch, useSelector} from 'react-redux';
import {setData, setFilter, setTablePagination} from '../redux/accessoriesSlice'

export const Accessories = () => {
    const {getDiscount} = useContext(DiscountsContext);
    const {notifications} = useContext(NotificationsContext)
    const [visible, setVisible] = useState(false);
    const [selectedArticle, setSelectedArticle] = useState({});
    const [showDiscount, setShowDiscount] = useState(true);
    const [selectedArticleQuantity, setSelectedArticleQuantity] = useState([
        {
            key: 'availability', label: 'Disponibilità immediata', overprice: 0, quantity: 0
        }
    ]);
    const {setItems} = useContext(CartCounterContext);
    const components = useSelector((state) => state.accessories.data);
    const pagination = useSelector((state) => state.accessories.pagination);
    const filter = useSelector((state) => state.accessories.filter);
    const dispatch = useDispatch();

    const [getComponents, {
        loading: componentsLoading,
        refetch
    }] = useLazyQuery(COMPONENTS_QUERY, {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
        onError: (error => {
            notifications.error(`Errore nel server: ${error.message}`)
        }),
        onCompleted: (data) => {
            dispatch(setData(data.getAllPertinentItems));
            dispatch(setTablePagination({...pagination, total: data.getAllPertinentItems.totalDocuments}));
        }
    });

    const columns = [
        {
            dataIndex: 'productImage',
            key: 'productImage',
            render: (text) => <Image
                src={text}
                width={50}
                fallback={placeholder}
                onClick={(e) => {
                    e.stopPropagation()
                }}
            />,
        },
        {
            title: 'Codice',
            dataIndex: 'partNumber',
            key: 'partNumber',
        },
        {
            title: 'Nome',
            dataIndex: 'title',
            key: 'title',
            width: '30%'
        },
        {
            title: 'Brand',
            dataIndex: 'brandLabel',
            key: 'brandLabel',
        },
        {
            title: 'Disponibilità',
            dataIndex: 'availability',
            key: 'availability',
            sorter: true,
            sortDirections: ['ascend', 'descend'],
            sortOrder: filter.sortField === 'availability' ? filter.order === 1 ? 'ascend' : 'descend' : null,
            render: (_, record) => {
                return record.availability ? record.availability : 0
            }
        },
        {
            title: 'In arrivo',
            key: 'orderedQuantity',
            sorter: true,
            sortDirections: ['ascend', 'descend'],
            sortOrder: filter.sortField === 'orderedQuantity' ? filter.order === 1 ? 'ascend' : 'descend' : null,
            render: (_, record) => {
                return record.orderedQuantity ? record.orderedQuantity : 0
            }
        },
        {
            title: (<div>Prezzo</div>),
            key: 'price',
            render: (_, record) => {
                if (!record.finalPrice)
                    return <Text>N/D</Text>

                const basePrice = beautifyNumber(record.finalPrice);
                if (showDiscount && getDiscount('accessori', record.brandLabel)) {
                    const priceDiscount = Math.round(record.finalPrice * (100 - getDiscount('accessori', record.brandLabel))) / 100;
                    return <Flex gap={"middle"}>
                        <Text>
                            <del>{basePrice} €</del>
                        </Text>
                        <Text strong>{beautifyNumber(priceDiscount)} €</Text>
                    </Flex>
                }
                return <Text strong>
                    {basePrice} €
                </Text>


            }
        },
    ];

    return <Flex vertical gap={'large'}>
        <Row align={'middle'}>
            <Col span={12}>
                <Title level={3}>Ricambi</Title>
            </Col>
            <Col span={12}>
                <Row align={'middle'}>
                    <Col span={12} offset={12}>
                        <Switch defaultChecked onChange={setShowDiscount} checked={showDiscount}/> Mostra prezzi
                        scontati
                    </Col>
                </Row>
            </Col>
        </Row>
        <PartsForm
            onFinish={getComponents}
        ></PartsForm>
        <AccessoryTable
            data={components}
            dataLoading={componentsLoading}
            handleShowArticle={(article) => {
                setSelectedArticle(article);
                setVisible(true);
            }}
            columns={columns}
            handleTableChange={async (pagination, _, sorter) => {
                dispatch(setFilter({
                    ...filter,
                    sortField: sorter.columnKey,
                    order: sorter.order === 'ascend' ? 1 : sorter.order === 'descend' ? -1 : undefined
                }))
                await refetch({
                    sortOrder: sorter.order === 'ascend' ? 1 : sorter.order === 'descend' ? -1 : undefined,
                    sortField: sorter.columnKey,
                    offset: pagination.pageSize * (pagination.current - 1),
                    limit: (pagination.pageSize * (pagination.current - 1)) + pagination.pageSize
                })
            }}
        ></AccessoryTable>
        <Drawer
            title={`${selectedArticle?.partNumber} - ${selectedArticle?.title}`}
            size={'large'}
            onClose={() => {
                setVisible(false)
                setSelectedArticle({})
                setSelectedArticleQuantity([
                    {
                        key: 'availability', label: 'Disponibilità immediata', overprice: 0, quantity: 0
                    }
                ])
            }}
            open={visible}>
            <AccessoryDetail
                selectedArticle={selectedArticle}
                setItems={setItems}
                setSelectedArticleQuantity={(value) => {
                    setSelectedArticleQuantity(value)
                }}
                articleDetailVoices={{
                    "aaiaid": "Produttore",
                    "brandLabel": "Brand",
                    "category": "Categoria",
                    "width": "Larghezza",
                    "length": "Lunghezza",
                    "height": "Altezza",
                    "weight": "Peso",
                    "warranty": "Garanzia"
                }}
                selectedArticleQuantity={selectedArticleQuantity}
                setVisible={setVisible}
                showDiscount={showDiscount}
            ></AccessoryDetail>
        </Drawer>
    </Flex>

}