import React, {useContext, useState} from 'react';
import {Alert, Button, Col, Divider, Flex, Form, Input, message, Row, Select, Spin, Upload} from 'antd';
import {UploadOutlined} from "@ant-design/icons";
import Papa from "papaparse";
import {Title} from "./Home";
import {COMPONENT_MAPPING_MUTATION, FITMENT_MAPPING_MUTATION} from "../apollo/apollo-queries";
import {useMutation} from "@apollo/client";
import {NotificationsContext} from "../components/common/NotificationsContext";
import {useNavigate} from "react-router-dom";

const allowedExtensions = ["text/csv", "text/plain", "xml"];
const possibleFitmentOptions = ['partNumber', 'aaiaid', 'year', 'make', 'model', 'submodel', 'brandLabel']
const possibleComponentsOptions = ['partNumber', 'aaiaid', 'brandLabel', 'partTerminologyLabel', 'category', 'title', 'price', 'productImage', 'width', 'length', 'height', 'weight', 'warranty', 'description']
const flipAndClean = (mapping) => {

    Object.keys(mapping).map(key => (!mapping[key] || mapping[key] === "") && delete mapping[key])

    const flipped = Object.keys(mapping).reduce((obj, key) => {
        const standarField = mapping[key];

        if (obj.hasOwnProperty(standarField))
            if (Array.isArray(obj[standarField])) return {
                ...obj,
                [standarField]: [...obj[standarField], key]
            }
            else return {...obj, [standarField]: [obj[standarField], key]}
        else return {...obj, [standarField]: key}
    }, {})

    return flipped;
}

const AddMapping = () => {
    const {notifications} = useContext(NotificationsContext)
    const navigate = useNavigate();
    //<editor-fold desc="FITMENT MAPPING MUTATION">
    const [postFitmentMapping, {
        loading: uploadFitmentMappingLoading
    }] = useMutation(FITMENT_MAPPING_MUTATION, {
        errorPolicy: "all", onCompleted: async () => {
            notifications.success("Upload eseguito");
            navigate('/mappings');
        }, onError: (error => {
            notifications.error(error.message)
        })
    });
    //</editor-fold>
    //<editor-fold desc="COMPONENT MAPPING MUTATION">
    const [postComponentMapping, {
        loading: uploadComponentMappingLoading
    }] = useMutation(COMPONENT_MAPPING_MUTATION, {
        errorPolicy: "all", onCompleted: () => {
            notifications.success("Upload eseguito");
            navigate('/mappings');
        }, onError: (error => {
            notifications.error(error.message)
        })
    });
    //</editor-fold>

    const [sampleHeader, setSampleHeader] = useState([]);
    const [parsingError, setParsingError] = useState("");
    const [selectedHeaderType, setSelectedHeaderType] = useState();
    const [loading, setLoading] = useState(false);
    const [mappingName, setMappingName] = useState("");
    const [mappedFields, setMappedFields] = useState({});
    const [appendFields, setAppendFields] = useState([]);

    const handleSampleFileChange = (e) => {
        if (e.file.status !== 'uploading') {
            console.log(e.file, e.fileList);
        }
        if (e.file.status === 'done') {
            notifications.success(`${e.file.name} file uploaded successfully`);
        } else if (e.file.status === 'error') {
            notifications.error(`${e.file.name} file upload failed.`);
        }

        setSelectedHeaderType();
        setSampleHeader([]);
        setParsingError("");
        setLoading(true);

        if (e.fileList.length) {
            const inputFile = e.file;

            const fileExtension = inputFile?.type;

            if (!allowedExtensions.includes(fileExtension)) {
                setLoading(false);
                setParsingError("Selezionare un file di tipo csv, txt o xml");
                return;
            }

            Papa.parse(inputFile, {
                delimiter: "", preview: 1, skipEmptyLines: true, complete: (results, file) => {
                    setLoading(false);
                    setSampleHeader(results.data[0])
                }
            });

        }
    };

    const checkMapping = (value) => {
        if (Object.keys(mappedFields).find((key) => mappedFields[key] === value)) {
            notifications.error(`E' vietato assegnare due volte il valore ${value}`)
            return false;
        }
        return true;
    }

    return <Flex vertical={true}>
        <Title level={3}> Aggiungi Mappatura</Title>
        <Form layout="vertical">
            <Row gutter={16} className="mb-3 mt-3">
                <Col span={8}>
                    <Form.Item label="Seleziona tipo file" name="type">
                        <Select
                            showSearch
                            id="type"
                            name="type"
                            onChange={(value) => setSelectedHeaderType(value)}
                            value={selectedHeaderType}
                            options={[
                                {key: "option-fitment", label: "Fitment", value: "fitment"},
                                {key: "option-component", label: "Componente", value: "component"},
                            ]}
                        >


                        </Select>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label="Nome mappatura" name="name">
                        <Input
                            type="text"
                            id="name"
                            name="name"
                            placeholder="Nome"
                            onChange={(e) => setMappingName(e.target.value)}
                            value={mappingName}
                            autoComplete="off"
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label="Scegli file" name="file">
                        <Upload beforeUpload={() => false} onChange={handleSampleFileChange}>
                            <Button icon={<UploadOutlined/>}>Select File</Button>
                        </Upload>
                    </Form.Item>
                </Col>
            </Row>
            <Row className="text-center">
                {loading && (
                    <Col span={24}>
                        <Alert
                            message={
                                <>
                                    <Spin size="small" className="me-1"/>
                                    <span>Caricamento in corso...</span>
                                </>
                            }
                            type="warning"
                            className="mt-2"
                        />
                    </Col>
                )}
                {parsingError && (
                    <Col span={24}>
                        <Alert
                            message={
                                <>
                                    <Spin size="small" className="me-1"/>
                                    <span>{parsingError}</span>
                                </>
                            }
                            type="error"
                            className="mt-2"
                        />
                    </Col>
                )}
            </Row>
        </Form>
        <Divider></Divider>
        <Form layout="vertical" style={{overflow: "scroll"}}>
            {selectedHeaderType !== "" && sampleHeader.map((field, index) => {
                return <Row key={`field-${index}`}>
                    <Col span={8}>
                        <Form.Item label={field} name={field} labelCol={8}>
                            <Select
                                showSearch
                                allowClear
                                id={field}
                                name={field}
                                onChange={(value) => {
                                    if (value === '') {
                                        setMappedFields(prev => {
                                            return {...prev, [field]: ''}
                                        });
                                    } else if (checkMapping(value)) {
                                        setMappedFields((mapping) => {
                                            return {...mapping, [field]: value}
                                        });
                                    }
                                }}
                                value={mappedFields[field]}
                                options={selectedHeaderType === "fitment" ?
                                    possibleFitmentOptions.map(option => ({
                                        disabled: Object.keys(mappedFields).some(key => mappedFields[key] === option),
                                        key: `option-fitment-${option}`,
                                        value: option,
                                        label: option
                                    })) :
                                    possibleComponentsOptions.map(option => ({
                                            disabled: Object.keys(mappedFields).some(key => mappedFields[key] === option),
                                            key: `option-fitment-${option}`,
                                            value: option,
                                            label: option
                                        })
                                    )}
                            >
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            })}
        </Form>
        <Row justify={"end"}>
            <Button variant={"success"} onClick={() => {

                const params = {
                    mapping: {
                        name: mappingName,
                        mappedFields: flipAndClean(mappedFields),
                        appendFields: appendFields.filter(item => item.key !== "" && item.value !== "")
                    }
                }

                selectedHeaderType === "fitment" ? postFitmentMapping({
                    variables: params
                }) : postComponentMapping({
                    variables: params
                })
            }}>Upload</Button>
        </Row>
        <Row className={"text-center"}>
            {(uploadFitmentMappingLoading || uploadComponentMappingLoading) &&
                <Alert type={"warning"} className={"mt-2"}>
                    <span>{`Caricamento in corso...`}</span>
                </Alert>}
        </Row>
    </Flex>
}

export default AddMapping;
