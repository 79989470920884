import React, {useState} from 'react';
import {Button, Upload} from 'antd';
import {UploadOutlined} from '@ant-design/icons';

const FilePicker = ({handleFileSelection}) => {
    const [fileList, setFileList] = useState([]);

    const props = {
        fileList: fileList,
        beforeUpload: (file) => {
            handleFileSelection(file);
            // Resetta la lista dei file
            setFileList([]);
            // Return false to prevent automatic upload
            return false;
        },
        onRemove: () => {
            setFileList([]);
        },
    };
    return (
        <Upload {...props}>
            <Button icon={<UploadOutlined/>}>Seleziona File</Button>
        </Upload>
    );
};

export default FilePicker;
