import {Button, Col, Flex, Image, InputNumber, List, Popconfirm, Row, Typography} from "antd";
import {Text, Title} from "../../views/Home";
import {beautifyNumber} from "./utils";
import {PlusIcon} from "@primer/octicons-react";
import {useNavigate} from "react-router-dom";
import {Fragment, useContext, useEffect, useState} from "react";
import {DiscountsContext} from "./DiscountContext";
import {useAuth0} from "@auth0/auth0-react";
import placeholder from "../../assets/placeholder.jpg";

export const GeneralDetail = ({
                                  selectedArticle,
                                  setSelectedArticleQuantity,
                                  articleDetailVoices,
                                  selectedArticleQuantity,
                                  setItems,
                                  setVisible,
                                  category,
                                  showDiscount,
                                  notes = <Fragment/>
                              }) => {
    const {getDiscount, getPaintDiscount} = useContext(DiscountsContext);
    const [discount, setDiscount] = useState(0);
    const navigate = useNavigate();
    const addToCart = () => {
        const newArticles = selectedArticleQuantity
            .filter(({quantity}) => quantity)
            .map((item, idx) => {
                return {
                    code: selectedArticle.article,
                    description: selectedArticle.description,
                    brand: selectedArticle.producerName,
                    deliveryOption: idx,
                    category,
                    unitPrice: {
                        base: selectedArticle.price,
                        overprice: 0,
                        overpriceUS: 0,
                        discount,
                        total: Math.round(selectedArticle.price * (100 - discount)) / 100
                    },
                    quantity: item.quantity,
                }
            })

        setItems(prev => [...prev, ...newArticles]);
    };
    const {user} = useAuth0();

    useEffect(() => {
        if (user && selectedArticle?.producerName) {
            if (!user["https://magnus-store.com/roles"].includes('admin')) {
                if (category !== 'vernici')
                    setDiscount(getDiscount(category, selectedArticle.producerName))
                else
                    setDiscount(getPaintDiscount(selectedArticleQuantity[0].quantity * selectedArticle.price))
            }
        }
    }, [category, discount, getDiscount, getPaintDiscount, selectedArticle, selectedArticleQuantity, user]);

    return <Flex vertical={true} className={'h-100'}>
        <Row>
            <Col span={12}>
                <Image
                    width={200}
                    src={selectedArticle.productImage}
                    fallback={placeholder}
                />
            </Col>
            <Col span={12}>
                <List
                    size={'small'}
                    bordered={false}
                    dataSource={Object.keys(articleDetailVoices)}
                    renderItem={(item) => (
                        <List.Item>
                            <Typography.Text
                                strong={true}>{articleDetailVoices[item]}:</Typography.Text> {selectedArticle[item] ? selectedArticle[item] : 'Non disponibile'}
                        </List.Item>
                    )}
                />
            </Col>
        </Row>
        {notes}
        <Flex vertical={true} style={{flexGrow: 1}} justify={"end"}>
            <List
                header={<Flex vertical={true}>
                    <Title level={5}>Opzioni disponibilità</Title>
                    <span
                        style={{color: "##7C7C7C"}}>Seleziona le opzioni di consegna e la relativa quantità</span>
                </Flex>}
                footer={
                    <Row align={'middle'}>
                        <Col span={12}>
                            <Row justify={"start"}>
                                Prezzo totale:
                                <Text strong>
                                    {beautifyNumber(selectedArticleQuantity.reduce((acc, item) => {
                                        return Math.round(acc + item.quantity * (selectedArticle.price * (100 - discount) / 100 + item.overprice) * 100) / 100
                                    }, 0))} €
                                </Text>
                            </Row>
                        </Col>
                        <Col span={12}>
                            <Row justify={"end"}>
                                <Popconfirm
                                    title="Vuoi continuare lo shopping?"
                                    onConfirm={() => {
                                        addToCart();
                                        setVisible(false);
                                    }}
                                    onCancel={() => {
                                        addToCart();
                                        navigate('/carrello');
                                    }}
                                    okText="Continua lo shopping"
                                    cancelText="Vai al carrello"
                                >
                                    <Button
                                        type="primary"
                                        htmlType="button"
                                        icon={<PlusIcon/>}
                                        disabled={selectedArticleQuantity.every(item => item.quantity === 0) || selectedArticleQuantity.some(item => item.quantity > parseInt(selectedArticle[item.key]))}
                                    >
                                        Aggiungi al carrello
                                    </Button>
                                </Popconfirm>

                            </Row>
                        </Col>
                    </Row>
                }
                size={'small'}
                bordered={false}
                dataSource={selectedArticleQuantity}
                renderItem={(item) => (<Row align={'middle'} className={'filter-form mb-1'}>
                    <Col span={12}>
                        <Flex vertical={true}>
                            {item.label}
                            {
                                (discount && showDiscount) ? <Flex gap={'small'}>
                                        <Text>
                                            <del>
                                                {beautifyNumber(selectedArticle.price + item.overprice)} € cad.
                                            </del>
                                        </Text>
                                        <Text strong>
                                            {beautifyNumber(selectedArticle.price * (100 - discount) / 100 + item.overprice)} €
                                            cad. (-{discount}%)
                                        </Text>
                                    </Flex> :
                                    <Text strong>
                                        {beautifyNumber(selectedArticle.price + item.overprice)} € cad.
                                    </Text>
                            }
                        </Flex>
                    </Col>
                    <Col span={6}>
                        Disponibili: {selectedArticle[item.key] ? selectedArticle[item.key] : 0}
                    </Col>
                    <Col span={6}>
                        <InputNumber
                            min={1}
                            max={selectedArticle[item.key]}
                            defaultValue={item.quantity}
                            disabled={!selectedArticle[item.key] || selectedArticle[item.key] === 0}
                            onChange={(value) => {
                                setSelectedArticleQuantity(prev => {
                                    return prev.map((innerItem) => item.key === innerItem.key ? {
                                        ...innerItem,
                                        quantity: value
                                    } : innerItem)
                                })
                            }}
                        />
                    </Col>
                </Row>)}
            >
            </List>
        </Flex>
    </Flex>
}