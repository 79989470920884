import {Button, Col, Divider, Form, Input, Row} from "antd";

export const AddressForm = ({handleSubmit, data}) => {
    const [form] = Form.useForm();
    return <Form
        layout={"vertical"}
        form={form}
        initialValues={data}
        onFinish={(values) => {
            handleSubmit({
                alternativeAddress: `${values.street} ${values.number} - ${values.city} (${values.postalCode}), ${values.state}`,
                alternativePhone: values.alternativePhone,
                alternativeDenomination: values.alternativeDenomination
            });
        }}
    >
        <Form.Item label="Denominazione" required={true} name={'alternativeDenomination'}>
            <Input placeholder="Denominazione"/>
        </Form.Item>
        <Divider></Divider>
        <Form.Item label="Telefono" required={true} name={'alternativePhone'}>
            <Input placeholder="Telefono"/>
        </Form.Item>
        <Divider></Divider>
        <Form.Item label="Via" required={true} name={'street'}>
            <Input placeholder="via"/>
        </Form.Item>
        <Row gutter={16}>
            <Col span={12}>
                <Form.Item label="Civico" required name={'number'}>
                    <Input placeholder="Civico"/>
                </Form.Item>

            </Col>
            <Col span={12}>
                <Form.Item label="CAP" required name={'postalCode'}>
                    <Input placeholder="CAP"/>
                </Form.Item>

            </Col>
        </Row>
        <Row gutter={16}>
            <Col span={12}>
                <Form.Item label="Comune" required name={'city'}>
                    <Input placeholder="Comune"/>
                </Form.Item>

            </Col>
            <Col span={12}>
                <Form.Item label="Provincia" required name={'state'}>
                    <Input placeholder="Provincia"/>
                </Form.Item>

            </Col>
        </Row>
        <Form.Item>
            <Button type="primary" htmlType={"submit"}>Fatto</Button>
        </Form.Item>
    </Form>
}