import {Alert, Button, Card, Flex, Image, Typography} from "antd";
import Meta from "antd/lib/card/Meta";
import {useNavigate} from "react-router-dom";
import ricambi from "../assets/ricambi.svg"
import accessori from "../assets/accessori.svg"
import lubrificanti from "../assets/lubrificanti.svg"
import cerchi from "../assets/cerchi.svg"
import vernici from "../assets/vernici.svg"
import {useAuth0} from "@auth0/auth0-react";

export const {Title, Text} = Typography;

export const Home = () => {
    const navigate = useNavigate();
    const {
        user
    } = useAuth0();

    return <Flex vertical gap={"large"}>
        <Title level={3}>CATEGORIE DI PRODOTTO</Title>
        <Alert type={"info"}
               message={"Entra nella categoria che ti interessa, aggiungi gli articoli al tuo carrello e conferma l’ordine. Troverai lo stato di avazamento del tuo ordine nella sezione Ordini"}></Alert>
        <Flex justify={'space-around'}>
            {user && user["https://magnus-store.com/roles"].includes('parts_client') && <Card
                onClick={() => navigate('ricambi')}
                hoverable
                style={{width: 240}}
                cover={<Image preview={false} height={150} alt="example" src={ricambi}/>}
                actions={[
                    <Button type={'link'}>VAI</Button>
                ]}
            >
                <Meta className={'text-center'} title="RICAMBI"/>
            </Card>}
            {user && user["https://magnus-store.com/roles"].includes('accessory_client') && <Card
                onClick={() => navigate('accessori')}
                hoverable
                style={{width: 240}}
                cover={<Image preview={false} height={150} alt="example" src={accessori}/>}
                actions={[
                    <Button type={'link'}>VAI</Button>
                ]}
            >
                <Meta className={'text-center'} title="ACCESSORI"/>
            </Card>}
            {user && user["https://magnus-store.com/roles"].includes('lubricants_client') && <Card
                onClick={() => navigate('lubrificanti')}
                hoverable
                style={{width: 240}}
                cover={<Image preview={false} height={150} alt="example" src={lubrificanti}/>}
                actions={[
                    <Button type={'link'}>VAI</Button>
                ]}
            >
                <Meta className={'text-center'} title="LUBRIFICANTI"/>
            </Card>}
            {user && user["https://magnus-store.com/roles"].includes('wheel_client') && <Card
                onClick={() => navigate('cerchi')}
                hoverable
                style={{width: 240}}
                cover={<Image preview={false} height={150} alt="example" src={cerchi}/>}
                actions={[
                    <Button type={'link'}>VAI</Button>
                ]}
            >
                <Meta className={'text-center'} title="CERCHI"/>
            </Card>}
            {user && user["https://magnus-store.com/roles"].includes('paints_client') && <Card
                onClick={() => navigate('vernici-raptor')}
                hoverable
                style={{width: 240}}
                cover={<Image preview={false} height={150} alt="example" src={vernici}/>}
                actions={[
                    <Button type={'link'}>VAI</Button>
                ]}
            >
                <Meta className={'text-center'} title="VERNICI RAPTOR"/>
            </Card>}
        </Flex>
    </Flex>
}