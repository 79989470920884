import {createSlice} from '@reduxjs/toolkit';

export const accessoriesSlice = createSlice({
    name: 'accessory',
    initialState: {
        data: [],
        filter: {
            offset: 0,
            limit: 6,
            sortOrder: -1,
            sortField: 'availability',
            category: 'lubrificanti',
        },
        pagination: {
            defaultPageSize: 6,
            pageSizeOptions: [6, 10, 50],
        },
    },
    reducers: {
        setData: (state, action) => {
            state.data = action.payload;
        },
        setFilter: (state, action) => {
            state.filter = action.payload;
        },
        setTablePagination: (state, action) => {
            state.pagination = action.payload;
        },
    },
});

export const {setData, setFilter, setTablePagination} = accessoriesSlice.actions;

export default accessoriesSlice.reducer;
