import { configureStore } from '@reduxjs/toolkit';
import cartReducer from './cartSlice';
import accessoriesReducer from './accessoriesSlice';
import wheelsReducer from './wheelsSlice';
import lubricantsReducer from './lubricantsSlice';
import paintsReducer from './paintsSlice';
import partsReducer from './partsSlice';

const store = configureStore({
    reducer: {
        cart: cartReducer,
        accessories: accessoriesReducer,
        wheels: wheelsReducer,
        lubricants: lubricantsReducer,
        paints: paintsReducer,
        parts: partsReducer,
    },
});

export default store;
