import {createContext, useContext, useEffect, useState} from 'react'
import {useAuth0} from "@auth0/auth0-react";
import {NotificationsContext} from "../common/NotificationsContext";
import {useQuery} from "@apollo/client";
import {ORDERS_QUERY} from "../../apollo/apollo-queries";

export const NewOrdersCounterContext = createContext()
export const NewOrdersCounterContextProvider = ({children}) => {
    const {user} = useAuth0();
    const {notifications} = useContext(NotificationsContext);
    const [newOrders, setNewOrders] = useState(0 )

    const {
        loading: ordersLoading,
        data: orders,
        refetch
    } = useQuery(ORDERS_QUERY, {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
        onError: error => {
            notifications.error(`Errore nel server: ${error.message}`)
        },
    });

    useEffect(() => {
        setNewOrders(()=>{
            const nOrders = orders?.orders.filter(({status}) => status === 0);
            return nOrders ? nOrders.length : 0;
        })
    }, [orders?.orders]);


    return <NewOrdersCounterContext.Provider value={{
        orders,
        ordersLoading,
        refetch,
        newOrders
    }}>
        {children}
    </NewOrdersCounterContext.Provider>
}