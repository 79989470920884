import {createSlice} from '@reduxjs/toolkit';

export const partsSlice = createSlice({
    name: 'parts',
    initialState: {
        filter: {fulltext: ''},
        pagination: {
            defaultPageSize: 6,
            pageSizeOptions: [6, 10, 50],
        },
        sort: {}
    },
    reducers: {
        setFilter: (state, action) => {
            state.filter = action.payload;
        },
        setTablePagination: (state, action) => {
            state.pagination = action.payload;
        },
        setSort: (state, action) => {
            state.sort = action.payload;
        },
    },
});

export const { setFilter, setTablePagination, setSort} = partsSlice.actions;

export default partsSlice.reducer;
