import React, {useEffect} from 'react';
import {Button, Col, Form, Input, InputNumber, Row} from 'antd';
import {SearchOutlined} from "@ant-design/icons";
import {useDispatch} from "react-redux";

const GeneralFilterForm = ({onFinish, filter, setFilter}) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    useEffect(() => {
        form.setFieldsValue(filter)
    }, []);

    return (<Form
        form={form}
        onFinish={(values) => {
            dispatch(setFilter(values))
            const conditions = [];
            if (values.fullText && values.fullText !== '')
                conditions.push((item) => {
                    return item.article.toLowerCase().includes(values.fullText.toLowerCase()) || item.description.toLowerCase().includes(values.fullText.toLowerCase());
                });

            onFinish((_) => (item) => {
                return conditions.every(condition => condition(item));
            });
        }}
        onReset={() => {
            form.resetFields();
            dispatch(setFilter({fulltext: ''}))
            onFinish(() => (_) => true);
        }}
        initialValues={{
            fullText: ''
        }}
        className={'filter-form'}
        layout={'vertical'}
    >
        <Row gutter={16}>
            <Col span={24}>
                <Form.Item name="fullText" className={'mb-1'} label="Codice">
                    <Input placeholder={`Digita il termine di ricerca...`} prefix={<SearchOutlined/>}/>
                </Form.Item>
            </Col>
        </Row>
        <Row>
            <Col span={12}>
                <div className={"d-inline-flex justify-content-start"}>
                    <Button type="default" htmlType="reset">
                        Reset
                    </Button>
                </div>
            </Col>
            <Col span={12}>
                <div className={"d-flex justify-content-end"}>
                    <Button type="primary" htmlType="submit">
                        Cerca
                    </Button>
                </div>
            </Col>
        </Row>
    </Form>);
};

export default GeneralFilterForm;
