import {Form, Select} from "antd";

export const AdminCartSelectCustomer = ({customers, onChange, selectedCustomer}) => {
    const [form] = Form.useForm();
    return <Form layout={"inline"}
                 className={'w-25'}
                 form={form}
                 initialValues={{customer: selectedCustomer}}
    >
        <Form.Item label="Cliente" name="customer" className={'w-100'}>
            <Select
                showSearch
                options={customers.map(({email}) => ({value: email, label: email}))}
                placeholder={'Seleziona cliente'}
                onChange={value => {
                    onChange(value);
                }}
            />
        </Form.Item>
    </Form>
}