import {Alert, Button, Col, Flex, Result, Row, Tag,} from "antd";
import React, {Fragment, useContext} from "react";
import {Title} from "./Home";
import {RollbackOutlined} from "@ant-design/icons";
import {useNavigate, useParams} from "react-router-dom";
import {CustomerTable} from "../components/orderDetails/CustomerTable";
import {AdminForm} from "../components/orderDetails/AdminForm";
import {DetailTableCustomer} from "../components/orderDetails/DetailTableCustomer";
import {DetailTableAdmin} from "../components/orderDetails/DetailTableAdmin";
import {useAuth0} from "@auth0/auth0-react";
import {useLazyQuery, useMutation, useQuery} from "@apollo/client";
import {CUSTOMER_QUERY, ORDER_QUERY, UPDATE_ORDER} from "../apollo/apollo-queries";
import {NotificationsContext} from "../components/common/NotificationsContext";
import {NewOrdersCounterContext} from "../components/orders/NewOrdersCounterContext";


export const OrderDetail = () => {
    const {notifications} = useContext(NotificationsContext)
    const {refetch} = useContext(NewOrdersCounterContext)
    const navigate = useNavigate();
    const {id} = useParams();

    const {
        loading: orderLoading,
        data: order,
        refetch: refetchOrder
    } = useQuery(ORDER_QUERY, {
        variables: {orderId: id},
        fetchPolicy: "no-cache",
        errorPolicy: "all",
        onError: error => {
            notifications.error(`Errore nel server: ${error.message}`)
        },
        onCompleted: (data) => {
            console.log(data)
            if (!data.order)
                navigate(['404'])
        }
    });

    const [updateOrder, {
        loading: loadingUpdate
    }] = useMutation(UPDATE_ORDER, {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
        onCompleted: async () => {
            notifications.success("Ordine modificato con successo!");
        },
        onError: (error => {
            notifications.error(`Errore nel server: ${error.message}`)
        })
    });

    const {user} = useAuth0();

    function getStatusTag(item) {
        switch (item) {
            case 0:
                return <Tag color={"red"}>Non spedito</Tag>
            case 1:
                return <Tag color={"green"}>Spedito</Tag>
            case 2:
                return <Tag color={"yellow"}>Annullato</Tag>
            default:
                return <Tag color={"blue"}>N/A</Tag>
        }

    }

    return (
        orderLoading ? <Fragment/> :
            order?.order ? <Flex vertical gap={'large'} className={'h-100'}>
                <Row align={"middle"}>
                    <Col span={12}>
                        <Flex justify={"start"}>
                            <Title level={3}>Ordine #{id}</Title>
                        </Flex>
                    </Col>
                    <Col span={12}>
                        <Flex justify={"end"}>
                            <Button icon={<RollbackOutlined/>} type={"default"}
                                    onClick={() => {
                                        refetch();
                                        navigate('..', {relative: "path"})
                                    }}> Torna agli ordini</Button>
                        </Flex>
                    </Col>
                </Row>
                {user && !user["https://magnus-store.com/roles"].includes('admin') &&
                    <CustomerTable
                        order={order.order}
                    ></CustomerTable>}
                {
                    user && user["https://magnus-store.com/roles"].includes('admin') &&
                    <AdminForm
                        order={order.order}
                        updateFn={(values) => {
                            const variables = {
                                order: {
                                    ...order.order,
                                    ...values
                                },
                                updateOrderId: id
                            };
                            delete variables.order.total;
                            delete variables.order.id;
                            updateOrder({
                                variables
                            }).then((res) => refetchOrder())

                        }}
                        refreshOrder={refetchOrder}
                    ></AdminForm>
                }
                {
                    user && !user["https://magnus-store.com/roles"].includes('admin') && order.order.notes && order.order.notes !== '' &&
                    <Alert type={"info"} message={order.order.notes}></Alert>
                }
                {
                    user && !user["https://magnus-store.com/roles"].includes('admin') &&
                    <DetailTableCustomer
                        getStatusTag={getStatusTag}
                        items={order.order.items}
                        loading={orderLoading}
                    ></DetailTableCustomer>
                }
                {
                    user && user["https://magnus-store.com/roles"].includes('admin') &&
                    <DetailTableAdmin
                        getStatusTag={getStatusTag}
                        items={order.order.items}
                        loading={orderLoading}
                        updateOrder={(values, closeFn) => {
                            const variables = {
                                order: {
                                    ...order.order,
                                    ...values,
                                },
                                updateOrderId: id
                            };
                            delete variables.order.total;
                            delete variables.order.id;
                            updateOrder({
                                variables
                            }).then((res) => refetchOrder())
                                .then(closeFn)
                        }}
                        loadingUpdate={loadingUpdate}
                    ></DetailTableAdmin>
                }
            </Flex> : <Result
                status="500"
                title="500"
                subTitle="Spiacenti, ordine inesistente."
                extra={<Button type="primary" onClick={() => navigate('/')}>Vai a home</Button>}
            />
    )
}
