import {Flex, Tabs, Typography} from "antd";
import {TrasportationCosts} from "../components/Globals/TrasportationCosts";
import {useQuery} from "@apollo/client";
import {SUPPLIERS_QEURY} from "../apollo/apollo-queries";
import {Margin} from "../components/Globals/Margin";
import {Variables} from "../components/Globals/Variables";
import WheelShipmentCosts from "../components/Globals/WheelsShipmentCosts";
import {useContext} from "react";
import {NotificationsContext} from "../components/common/NotificationsContext";

export const {Title, Text} = Typography;

export const Globals = () => {
    const {notifications} = useContext(NotificationsContext)

    const {
        loading: suppliersLoading, data: suppliers
    } = useQuery(SUPPLIERS_QEURY, {
        errorPolicy: "all",
        onCompleted: data => {
        },
        onError: error => {
            notifications.error(`Errore nel server: ${error.message}`)
        }
    });

    return <Flex vertical={true}>
        <Title level={3}>GESTIONE VARIABILI</Title>
        <Tabs
            defaultActiveKey={1}
            items={[
                {route: "variables", label: "Variabili", children: <Variables/>},
                {
                    route: "transportation_costs",
                    label: "Costi di trasporto",
                    children: <TrasportationCosts suppliersLoading={suppliersLoading} suppliers={suppliers}/>
                },
                {
                    route: "margin",
                    label: "Margini",
                    children: <Margin suppliersLoading={suppliersLoading} suppliers={suppliers}/>
                },
                {
                    route: "wheels-shipment-costs",
                    label: "Sovrapprezzi cerchi",
                    children: <WheelShipmentCosts/>
                }
            ].map((item, i) => {
                return {
                    label: item.label,
                    key: i,
                    children: item.children,
                };
            })}
        />
    </Flex>

}