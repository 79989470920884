import {Button, Drawer, Flex, Table} from "antd";
import React, {Fragment, useState} from "react";
import {EditOutlined} from "@ant-design/icons";
import {EditForm} from "./EditForm";
import {beautifyNumber, getDeliveryOption} from "../common/utils";
import {Text} from "../../views/Home";

export const DetailTableAdmin = ({getStatusTag, items, loading, updateOrder, loadingUpdate}) => {

    const [visible, setVisible] = useState(false)
    const [selectedIdx, setSelectedIdx] = useState()
    const [pagination, setPagination] = useState({current: 1, pageSize: 5});

    const handleTableChange = (current, size) => {
        setPagination({current: current, pageSize: size});
    };

    const orderColumns = [
        {
            title: 'Codice',
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: 'Descrizione',
            dataIndex: 'description',
            key: 'description',
            width: '25%'
        },
        {
            title: 'Brand',
            dataIndex: 'brand',
            key: 'brand',
        },
        {
            title: 'Quantità',
            dataIndex: 'quantity',
            key: 'quantity'
        },
        {
            title: 'Consegna',
            dataIndex: 'deliveryOption',
            key: 'delivery',
            render: getDeliveryOption
        },
        {
            title: "Sconto",
            dataIndex: 'unitPrice',
            key: 'discount',
            render: (_, {unitPrice}) => {
                return `${unitPrice.discount} %` ? unitPrice.discount : 'N/D';
            }
        },
        {
            title: (<div>Prezzo <br/> unitario</div>),
            dataIndex: 'unitPrice',
            key: 'unitPrice',
            render: (_, {unitPrice}) => {
                if (unitPrice.discount)
                    return <Flex vertical={true}>
                        <Text
                            delete={true}>{beautifyNumber(unitPrice.base + unitPrice.overprice + unitPrice.overpriceUS)} €</Text>
                        <Text>{beautifyNumber((unitPrice.base * (100 - unitPrice.discount) / 100) + unitPrice.overprice + unitPrice.overpriceUS)} €</Text>
                    </Flex>;
                return `${beautifyNumber(unitPrice.base + unitPrice.overprice + unitPrice.overpriceUS)} €`
            }
        },
        {
            title: (<div>Totale <br/> (iva escl.)</div>),
            key: 'total',
            render: (_, {quantity, unitPrice}) => {
                if (unitPrice.discount)
                    return <Flex vertical={true}>
                        <Text
                            delete={true}>{beautifyNumber(quantity * (unitPrice.base + unitPrice.overprice + unitPrice.overpriceUS))} €</Text>
                        <Text
                            delete={false}>{beautifyNumber(quantity * ((unitPrice.base * (100 - unitPrice.discount) / 100) + unitPrice.overprice + unitPrice.overpriceUS))} €</Text>
                    </Flex>
                return `${beautifyNumber(quantity * (unitPrice.base + unitPrice.overprice + unitPrice.overpriceUS))} €`;
            }
        },
        {
            title: 'Stato',
            dataIndex: 'status',
            render: (item) => getStatusTag(item)
        },
        {
            render: (_, item, index) => <Button type={"default"} icon={<EditOutlined/>} onClick={() => {
                setVisible(true);
                setSelectedIdx((pagination.current - 1) * pagination.pageSize + index);
            }}></Button>
        }
    ];

    return <Fragment>
        <Table
            dataSource={items}
            loading={loading}
            columns={orderColumns}
            pagination={{...pagination, onChange: handleTableChange}}
            rowKey={(record) => record.id}
        >

        </Table>
        <Drawer
            title={"Modifica dettaglio ordine"}
            size={'default'}
            onClose={() => {
                setVisible(false)
            }}
            open={visible}
        >
            <EditForm
                item={(selectedIdx !== undefined) ? items[selectedIdx] : {}}
                updateFn={(values) => {
                    updateOrder({
                        items: items.map((item, idx) => {
                            if (idx === selectedIdx) {
                                return {
                                    ...item,
                                    ...values
                                };
                            } else {
                                return item;
                            }
                        })
                    }, () => setVisible(false))

                }}
                loadingUpdate={loadingUpdate}
            ></EditForm>
        </Drawer>
    </Fragment>
}
