import {Table} from "antd";
import React from "react";
import {useNavigate} from "react-router-dom";
import {beautifyNumber} from "../common/utils";


export const OrdersTableCustomer = ({getStatusTag, loading, orders}) => {
    const navigate = useNavigate();
    const columns = [
        {
            title: 'ID ordine',
            dataIndex: 'id',
            width: '25%',
        },
        {
            title: 'Data ordine',
            dataIndex: 'orderDate',
            width: '25%',
            sorter: (a, b) => a.orderDate > b.orderDate ? 1 : -1,
            sortDirections: ['ascend', 'descend'],
            defaultSortOrder: 'descend',

        },
        {
            title: 'Importo',
            dataIndex: 'total',
            width: '25%',
            sorter: (a, b) => a.total > b.total ? 1 : -1,
            sortDirections: ['ascend', 'descend'],
            render: (item) => `${beautifyNumber(item)} €`
        },
        {
            title: 'Stato',
            dataIndex: 'status',
            width: '25%',
            render: (item) => getStatusTag(item)
        }
    ];

    return <Table
        dataSource={orders}
        loading={loading}
        columns={columns}
        pagination={{pageSize: 5}}
        rowClassName={'wheelTableRow'}
        onRow={(record) => {
            return {
                onClick: () => {
                    navigate(record.id)
                }
            }
        }}
        rowKey={(record) => record.id}
    >

    </Table>

}