import {Flex, Table} from "antd";
import React from "react";
import {beautifyNumber, getDeliveryOption} from "../common/utils";
import {Link} from "react-router-dom";
import {Text} from "../../views/Home";

export const DetailTableCustomer = ({getStatusTag, items}) => {
    const orderColumns = [
        {
            title: 'Codice',
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: 'Descrizione',
            dataIndex: 'description',
            key: 'description',
            width: '30%'
        },
        {
            title: 'Brand',
            dataIndex: 'brand',
            key: 'brand',
        },
        {
            title: 'Quantità',
            dataIndex: 'quantity',
            key: 'quantity'
        },
        {
            title: 'Consegna',
            dataIndex: 'deliveryOption',
            key: 'delivery',
            render: getDeliveryOption
        },
        {
            title: "Sconto",
            dataIndex: 'unitPrice',
            key: 'discount',
            render: (_, {unitPrice}) => {
                return `${unitPrice.discount} %` ? unitPrice.discount : 'N/D';
            }
        },
        {
            title: (<div>Prezzo <br/> unitario</div>),
            dataIndex: 'unitPrice',
            key: 'unitPrice',
            render: (_, {unitPrice}) => {
                if (unitPrice.discount)
                    return <Flex vertical={true}>
                        <Text
                            delete={true}>{beautifyNumber(unitPrice.base + unitPrice.overprice + unitPrice.overpriceUS)} €</Text>
                        <Text>{beautifyNumber((unitPrice.base * (100 - unitPrice.discount) / 100) + unitPrice.overprice + unitPrice.overpriceUS)} €</Text>
                    </Flex>;
                return `${beautifyNumber(unitPrice.base + unitPrice.overprice + unitPrice.overpriceUS)} €`
            }
        },
        {
            title: (<div>Totale <br/> (iva escl.)</div>),
            key: 'total',
            render: (_, {quantity, unitPrice}) => {
                if(unitPrice.discount)
                    return <Flex vertical={true}>
                        <Text delete={true}>{beautifyNumber(quantity * (unitPrice.base  + unitPrice.overprice + unitPrice.overpriceUS))} €</Text>
                        <Text delete={false}>{beautifyNumber(quantity * ((unitPrice.base * (100 - unitPrice.discount) / 100) + unitPrice.overprice + unitPrice.overpriceUS))} €</Text>
                    </Flex>
                return `${beautifyNumber(quantity * (unitPrice.base  + unitPrice.overprice + unitPrice.overpriceUS))} €`;
            }
        },
        {
            title: 'Stato',
            dataIndex: 'status',
            render: (item, record) => <Flex vertical={true}>
                {getStatusTag(item)}
                {record.trackingID && record.trackingID !== '' && <a href={record.trackingID} target="_blank" rel="noopener noreferrer">Vedi il tracking</a>}
            </Flex>
        },
    ];

    return <Table
        dataSource={items}
        columns={orderColumns}
        pagination={{pageSize: 5}}
        rowClassName={'wheelTableRow'}
        rowKey={(_, idx) => idx}
    >
    </Table>
}
