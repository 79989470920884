import {Alert, Button, Col, DatePicker, Drawer, Flex, Form, Input, Row, Select} from "antd";
import {Text} from "../../views/Home";
import React, {Fragment, useContext, useEffect, useState} from "react";
import {InfoIcon} from "@primer/octicons-react";
import TextArea from "antd/lib/input/TextArea";
import moment from 'moment'
import {useLazyQuery, useQuery} from "@apollo/client";
import {CUSTOMER_QUERY, PHONE_QUERY} from "../../apollo/apollo-queries";
import {NotificationsContext} from "../common/NotificationsContext";
import {beautifyNumber} from "../common/utils";

export const AdminForm = ({order, updateFn, refreshOrder}) => {
    const {notifications} = useContext(NotificationsContext)
    const [form] = Form.useForm();
    const [shipmentVisible, setShipmentVisible] = useState(false);
    const [hasChanged, setHasChanged] = useState(false);
    const [generalNotes, setGeneralNotes] = useState()
    const {data: phoneQueryData} = useQuery(PHONE_QUERY, {
        variables: {mail: order.customer},
        fetchPolicy: "no-cache",
        errorPolicy: "all",
        onError: error => {
            notifications.error(`Errore nel server: ${error.message}`)
        },
    });

    const [address, setAddress] = useState()

    const  {
        data: account,
    } = useQuery(CUSTOMER_QUERY, {
        variables: {mail: order.customer},
        fetchPolicy: "network-only",
        errorPolicy: "all",
        onError: error => {
            notifications.error(`Errore nel server: ${error.message}`)
        },
        onCompleted: (data) => {
            if (data.customer) {
                setAddress(`${data.customer.street} ${data.customer.postalCode}, ${data.customer.city}, ${data.customer.state}`)
                setGeneralNotes(data.customer.notes)
            }

        }
    });


    return <Flex vertical={true}>
        <Row>
            <Col span={6}>
                <Text strong>Cliente:</Text> {order.customer}
            </Col>
            <Col span={6}>
                <Text strong>Data ordine:</Text> {order.orderDate}
            </Col>
            <Col span={6}>
                <Text strong>Totale ordine:</Text> {beautifyNumber(order.total)} €
            </Col>
            <Col span={6}>
                <Row justify={"end"}>
                    <Button type={"default"} icon={<InfoIcon/>} onClick={() => setShipmentVisible(true)}>Vedi dettagli
                        spedizione</Button>
                </Row>
            </Col>
        </Row>
        <Form
            layout={"vertical"}
            form={form}
            initialValues={{
                trackingID: order.trackingID,
                status: order.status,
                deliveryDate: order.deliveryDate ? moment.unix(parseFloat(order.deliveryDate) / 1000) : null,
                notes: order.notes
            }}
            onFieldsChange={() => {
                const values = form.getFieldsValue();
                setHasChanged(Object.keys(values).some(key => {
                    return order && values[key] !== order[key]
                }))
            }}
            onFinish={(values) => {
                if (values.deliveryDate) {
                    values.deliveryDate = values.deliveryDate.toString()
                }
                updateFn(values)
                refreshOrder()
            }}
            className={'filter-form mt-2'}
        >
            <Row gutter={16}>
                <Col span={6}>
                    <Form.Item label="Note opzionali" name={'notes'}>
                        <TextArea placeholder="Note per il cliente"/>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label="Tracking id" name={'trackingID'}>
                        <Input placeholder="ID"/>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label="Data di consegna" name={'deliveryDate'}>
                        <DatePicker className={'w-100'}/>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label="Stato" name={'status'}>
                        <Select
                            showSearch
                            options={[
                                {
                                    value: 0,
                                    label: 'Ricevuto',
                                },
                                {
                                    value: 1,
                                    label: 'Approvato',
                                },
                                {
                                    value: 2,
                                    label: 'In arrivo',
                                },
                                {
                                    value: 4,
                                    label: 'Spedito parziale',
                                },
                                {
                                    value: 3,
                                    label: 'Concluso',
                                },
                                {
                                    value: 5,
                                    label: 'Annullato',
                                }
                            ]}
                            initialValue={order?.status}
                        />
                    </Form.Item>

                </Col>
            </Row>
            <Row justify={"end"}>
                <Form.Item>
                    <Button type="primary" htmlType={"submit"} disabled={!hasChanged}>Aggiorna ordine</Button>
                </Form.Item>
            </Row>
        </Form>
        <Drawer
            title={"Info ordine"}
            size={'default'}
            onClose={() => {
                setShipmentVisible(false)
            }}
            open={shipmentVisible}>
            <Flex vertical={true} gap={"middle"}>
                {(order.alternativeAddress || order.alternativeDenomination || order.alternativePhone) &&
                    <Alert type={"warning"}
                           message={
                               <Fragment>
                                   <Text strong={true}>ATTENZIONE:</Text>
                                   <Text> Informazioni cliente personalizzate.</Text>
                               </Fragment>
                           }/>}
                <Flex vertical>
                    <Text strong>Denominazione:</Text>
                    <Text>{order.alternativeDenomination && order.alternativeDenomination !== '' ? order.alternativeDenomination : account?.customer.businessName}</Text>
                </Flex>
                <Flex vertical>
                    <Text strong>Indirizzo:</Text>
                    <Text>{order.alternativeAddress && order.alternativeAddress !== '' ? order.alternativeAddress : address}</Text>
                </Flex>
                <Flex vertical>
                    <Text strong>Telefono:</Text>
                    <Text>{order.alternativePhone && order.alternativePhone !== '' ? order.alternativePhone : phoneQueryData?.customer.phone}</Text>
                </Flex>
                {order.customerNotes && <Flex vertical>
                    <Text strong>Note ordine:</Text>
                    <Text>{order.customerNotes}</Text>
                </Flex>}
                {
                    generalNotes && <Flex vertical>
                        <Text strong>Note generali del cliente:</Text>
                        <Text>{generalNotes}</Text>
                    </Flex>
                }
            </Flex>
        </Drawer>
    </Flex>
}
