import {Flex, Tabs, Typography} from "antd";
import {CustomerDiscount} from "../components/Globals/CustomerDiscount";
import {SupplierDiscounts} from "../components/Globals/SupplierDiscounts";

export const {Title, Text} = Typography;

export const Discounts = () => {

    return <Flex vertical={true}>
        <Title level={3}>GESTIONE SCONTISTICA</Title>
        <Tabs
            defaultActiveKey={0}
            items={[
                {
                    route: "customer-discounts",
                    label: "Sconti clienti",
                    children: <CustomerDiscount/>
                },
                {
                    route: "supplier-discounts",
                    label: "Sconti fornitori",
                    children: <SupplierDiscounts/>
                }
            ].map((item, i) => {
                return {
                    label: item.label,
                    key: i,
                    children: item.children,
                };
            })}
        />
    </Flex>

}