import {Button, Flex, Row, Table, Typography} from "antd";
import {useNavigate} from "react-router-dom";
import React, {useContext} from "react";
import {DELETE_COMPONENT_QUERY, DELETE_FITMENT_QUERY, MAPPINGS_QUERY} from "../apollo/apollo-queries";
import {useMutation, useQuery} from "@apollo/client";
import {PlusIcon, TrashIcon} from "@primer/octicons-react";
import {NotificationsContext} from "../components/common/NotificationsContext";

export const {Title, Text} = Typography;

export const Mappings = () => {
    const {notifications} = useContext(NotificationsContext)

    const {
        data: mappings, loading: mappingLoading, refetch: refetchMappings
    } = useQuery(MAPPINGS_QUERY, {
        errorPolicy: "all",
        onError: (error) => notifications.error(`Errore nel server: ${error.message}`),
    });
    //<editor-fold desc="DELETE COMPONENT MAPPING MUTATION">
    const [deleteComponentMapping, {
        loading: deletingComponentMapping
    }] = useMutation(DELETE_COMPONENT_QUERY, {
        errorPolicy: "all", onCompleted: async () => {
            await refetchMappings()
        },
        onError: (error => {
            notifications.error(`Errore nel server: ${error.message}`)
        })
    });
    //</editor-fold>
    //<editor-fold desc="DELETE FITMENT MAPPING MUTATION">
    const [deleteFitmentMapping, {
        loading: deletingFitmentMapping
    }] = useMutation(DELETE_FITMENT_QUERY, {
        errorPolicy: "all", onCompleted: async () => {
            await refetchMappings()
        },
        onError: (error => {
            notifications.error(`Errore nel server: ${error.message}`)
        })
    });
    //</editor-fold>
    const navigate = useNavigate();
    const columns = [
        {
            title: 'Nome',
            dataIndex: 'name',
        },
        {
            title: 'Tipo mapping',
            dataIndex: 'type',
            filters: [
                {
                    text: 'Fitment',
                    value: 'fitment',
                },
                {
                    text: 'Component',
                    value: 'component',
                },
            ],
            onFilter: (value, record) => record.type === value,
        },
        {
            width: '5%',
            render: (_, {type, name}) => <Button
                icon={<TrashIcon/>}
                type={"link"}
                loading={deletingComponentMapping || deletingFitmentMapping}
                onClick={async () => {
                    // eslint-disable-next-line no-restricted-globals
                    if (confirm('Sicuro di voler eliminare la mappatura?')) {
                        if (type === "fitment")
                            await deleteFitmentMapping({
                                variables: {name}
                            })
                        else
                            await deleteComponentMapping({
                                variables: {name}
                            })
                    }
                }}/>
        }
    ];

    return <Flex vertical gap={"large"}>
        <Title level={3}>Storico</Title>
        <Row justify={"end"}>
            <Button icon={<PlusIcon/>} type={"primary"} onClick={() => navigate('new-mapping')}>
                Aggiungi mapping
            </Button>
        </Row>
        <Table
            size={"small"}
            dataSource={mappings?.getMappings}
            columns={columns}
            loading={mappingLoading}
            pagination={{pageSize: 10}}
            rowKey={(record) => record.name}/>
    </Flex>

}