import {Table} from "antd";
import React, {useContext} from "react";
import {useNavigate} from "react-router-dom";
import {beautifyNumber} from "../common/utils";
import {useQuery} from "@apollo/client";
import {CUSTOMERS_QUERY} from "../../apollo/apollo-queries";
import {NotificationsContext} from "../common/NotificationsContext";


export const OrdersTableAdmin = ({getStatusTag, searchValue, orders, loading}) => {
    const {notifications} = useContext(NotificationsContext)
    const {data: customers} = useQuery(CUSTOMERS_QUERY, {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
        onError: error => {
            notifications.error(`Errore nel server: ${error.message}`)
        },
    });

    const navigate = useNavigate();
    const columns = [
        {
            title: 'ID ordine',
            dataIndex: 'id',
            width: '10%',
        },
        {
            title: 'Cliente',
            dataIndex: 'customer',
            width: '20%',
        },
        {
            title: 'Azienda',
            width: '20%',
            render: (_, record) => {
                const result = customers?.customers.find((item) => item.email === record.customer)?.businessName;
                return result ? result : 'n/d';
            }
        },
        {
            title: 'Data ordine',
            dataIndex: 'orderDate',
            width: '20%',
            sorter: (a, b) => a.orderDate > b.orderDate ? 1 : -1,
            sortDirections: ['ascend', 'descend'],
            defaultSortOrder: 'descend',
        },
        {
            title: 'Importo',
            dataIndex: 'total',
            width: '10%',
            sorter: (a, b) => a.total > b.total ? 1 : -1,
            sortDirections: ['ascend', 'descend'],
            render: (item) => `${beautifyNumber(item)} €`
        },
        {
            title: 'Stato',
            dataIndex: 'status',
            width: '10%',
            render: (item) => getStatusTag(item)
        }
    ];

    return <Table
        dataSource={orders?.filter(item => item.id === searchValue.text || item.customer.includes(searchValue.text) || searchValue.status.includes(item.status))}
        columns={columns}
        loading={loading}
        pagination={{pageSize: 5}}
        rowClassName={'wheelTableRow'}
        onRow={(record) => {
            return {
                onClick: () => {
                    navigate(record.id)
                }
            }
        }}
        rowKey={(record) => record.id}
    >

    </Table>

}