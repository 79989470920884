import {Button, Flex, Form, InputNumber, Select, Table} from "antd";
import {Title} from "../../views/Home";
import React, {useContext, useState} from "react";
import {useMutation, useQuery} from "@apollo/client";
import {ADD_DISCOUNT_QUERY, DISCOUNTS_QUERY, SUPPLIERS_QEURY} from "../../apollo/apollo-queries";
import {DeleteFilled, EditOutlined} from "@ant-design/icons";
import {NotificationsContext} from "../common/NotificationsContext";


export const SupplierDiscounts = () => {
    const {notifications} = useContext(NotificationsContext)
    const {
        loading: suppliersLoading, data: suppliers
    } = useQuery(SUPPLIERS_QEURY, {
        errorPolicy: "all",
        onCompleted: data => {
        },
        onError: error => {
            notifications.error('Errore nel server: ' + error.message)
        }
    });

    const {
        loading: discountsLoading, data: originalDiscounts, refetch: refetchDiscounts
    } = useQuery(DISCOUNTS_QUERY, {
        errorPolicy: "all",
        onCompleted: data => {
        },
        onError: error => {
            notifications.error('Errore nel server: ' + error.message)
        }
    });

    const [addDiscount, {
        loading: addDiscountLoading
    }] = useMutation(ADD_DISCOUNT_QUERY, {
        errorPolicy: "all",
        onCompleted: async (data) => {
            await refetchDiscounts()
        },
        onError: error => {
            notifications.error('Errore nel server: ' + error.message)
        }
    });

    const [form] = Form.useForm();
    const [updating, setUpdating] = useState()

    const columns = [
        {
            title: 'Fornitore',
            dataIndex: 'name',
            render: (text) => suppliers?.getSuppliers.find(supplier => supplier.aaiaid === text)?.brandLabel
        },
        {
            title: 'Sconto',
            dataIndex: 'value',
            render: text => `${text} %`
        },
        {
            width: '5%',
            render: (_, record) => <Flex gap={"small"}>
                <Button
                    type={"link"}
                    icon={<DeleteFilled/>}
                    onClick={() => {
                        alert("Funzionalita' non implementata!")
                    }}
                ></Button>
                <Button
                    type={"link"}
                    htmlType={"button"}
                    icon={<EditOutlined/>}
                    onClick={() => {
                        setUpdating(true)
                        form.setFieldsValue({
                            supplier: record.name,
                            value: parseFloat(record.value)
                        })
                    }}
                ></Button>
            </Flex>
        }
    ];

    const onFinish = async (values) => {
        await addDiscount({
            variables: {
                aaiaid: values.supplier, cost: parseFloat(values.value)
            }
        })
    }

    return <Flex vertical gap={"large"}>
        <Flex vertical={true}>
            <Title level={5}>{updating ? 'Aggiorna' : 'Aggiungi'} margine:</Title>
            <Form form={form} layout="inline" onFinish={onFinish} onReset={() => setUpdating(false)}>
                <Form.Item
                    name="supplier"
                    rules={[{required: true, message: 'Selezionare fornitore!'}]}
                >
                    <Select
                        showSearch
                        placeholder="Seleziona il fornitore"
                        style={{width: 200}}
                        options={suppliers?.getSuppliers.map((supplier, idx) => {
                            return {
                                value: supplier.aaiaid,
                                label: supplier.brandLabel,
                                key: idx
                            }
                        })}
                    >
                    </Select>
                </Form.Item>

                <Form.Item
                    name="value"
                    rules={[{required: true, message: 'Inserire valore'}]}
                >
                    <InputNumber min={0} placeholder="Costo" style={{width: 200}}/>
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={addDiscountLoading}>
                        Fatto
                    </Button>
                </Form.Item>
                <Form.Item>
                    <Button type="default" htmlType="reset">
                        Reset
                    </Button>
                </Form.Item>
            </Form>
        </Flex>
        <Table
            size={"small"}
            dataSource={originalDiscounts?.getDiscounts}
            loading={discountsLoading || suppliersLoading}
            columns={columns}
            pagination={{pageSize: 10}}
            rowKey={(record) => record.name}

        >

        </Table>

    </Flex>

}