import {createContext, useContext, useEffect} from 'react'
import {useAuth0} from "@auth0/auth0-react";
import {useLazyQuery,} from "@apollo/client";
import {GET_CUSTOMER_DISCOUNT} from "../../apollo/apollo-queries";
import {NotificationsContext} from "./NotificationsContext";

export const DiscountsContext = createContext()
export const DiscountsProvider = ({children}) => {
    const {notifications} = useContext(NotificationsContext)
    const {user} = useAuth0();
    const [fetchDiscount, {
        data
    }] = useLazyQuery(GET_CUSTOMER_DISCOUNT, {
        errorPolicy: "all",
        onError: error => notifications.error('Impossibile caricare gli sconti.')
    });

    useEffect(() => {
        if (user) {
            if (user["https://magnus-store.com/roles"].includes('admin'))
                fetchDiscount();
            else
                fetchDiscount({variables: {customer: user.email}});
        }
    }, [fetchDiscount, user]);

    const getPaintDiscount = (total) => {
        if (total < 750)
            return 25;
        if (total < 1500)
            return 30;
        return 35;
    }
    const getDiscount = (target, brand) => {
        const discount = data?.getCustomerDiscounts.find(item => item.user === user.email && item.target === target && item.brand.toLowerCase() === brand.toLowerCase())?.discount;
        return discount ? discount : 0;
    }

    const getUserDiscount = (user, target, brand) => {
        if (user) {
            const discount = data?.getCustomerDiscounts.find(item => item.user === user && item.target === target && item.brand.toLowerCase() === brand.toLowerCase())?.discount;
            return discount ? discount : 0;
        }
        return 0;
    }

    return <DiscountsContext.Provider value={{
        getDiscount,
        getUserDiscount,
        getPaintDiscount
    }}>
        {children}
    </DiscountsContext.Provider>
}