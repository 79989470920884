import React, {useContext, useState} from 'react';
import {Button, Drawer, Flex, Modal, Row, Select, Space, Table} from 'antd';
import {DeleteOutlined, PlusOutlined} from '@ant-design/icons';
import {useLazyQuery, useMutation, useQuery} from "@apollo/client";
import {ALL_ROLES, ASSIGN_ROLE, DELETE_ROLE, DELETE_USER, GET_USERS_ROLES} from "../../apollo/apollo-queries";
import UserRegistrationForm from "./UserRegistrationForm";
import {NotificationsContext} from "../common/NotificationsContext";


const UserTable = ({users, refetch, loading}) => {
    const {notifications} = useContext(NotificationsContext)

    const {data: allRoles} = useQuery(
        ALL_ROLES,
        {
            errorPolicy: "all",
            fetchPolicy: "no-cache",
            onError: error => {
                notifications.error(`Errore nel server: ${error.message}`);
            },
        }
    );
    const [getRoles, {
        loading: rolesLoading,
        data: originalRoles
    }] = useLazyQuery(GET_USERS_ROLES, {
        errorPolicy: "all",
        fetchPolicy: "no-cache",
        onCompleted: data => {
            setSelectedRoles(data.getUserRoles)
        },
        onError: error => {
            notifications.error(`Errore nel server: ${error.message}`);
        },
    });

    const [deleteUser, {
        loading: deleteUserLoading
    }] = useMutation(DELETE_USER, {
        errorPolicy: "all",
        onCompleted: async () => {
            await refetch();
        }, onError: error => {
            notifications.error(`Errore nel server: ${error.message}`);
        }
    });

    const [deleteRole, {
        loading: deleteRoleLoading
    }] = useMutation(DELETE_ROLE, {
        errorPolicy: "all",
        onCompleted: async () => {
            await refetch();
            notifications.info("Utente aggiunto con successo")
        },
        onError: error => {
            notifications.error(`Errore nel server: ${error.message}`)
        }
    });

    const [assignRole, {
        loading: assignRoleLoading
    }] = useMutation(ASSIGN_ROLE, {
        errorPolicy: "all",
        onCompleted: async (data) => {
            await refetch();
        },
        onError: error => {
            notifications.error(`Errore nel server: ${error.message}`);
        }
    });

    const openModal = async (idx) => {
        await getRoles({variables: {user: users?.customers[idx]?.email}})
        setModalOpen(true);
    }

    const [isModalOpen, setModalOpen] = useState(false);
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [visible, setVisible] = useState(false)
    const columns = [
        {
            title: 'Uente',
            dataIndex: 'user',
            key: 'user',
        },
        {
            title: 'Azienda',
            dataIndex: 'businessName',
            key: 'businessName',
        },
        {
            width: '10%',
            render: (text, record) => (
                <Space size="middle">
                    <Button type="link" loading={selectedUser === parseInt(record.key) && rolesLoading}
                            onClick={async () => {
                                setSelectedUser(parseInt(record.key));
                                await openModal(parseInt(record.key));
                            }}>
                        Gestisci ruoli
                    </Button>
                    <Button type="link" danger onClick={async () => {
                        // eslint-disable-next-line no-restricted-globals
                        if (confirm(`Sicuro di voler eliminare ${record.user}`))
                            await deleteUser({
                                variables: {
                                    mail: record.user
                                }
                            })
                    }}>
                        <DeleteOutlined/>
                    </Button>
                </Space>
            ),
        },
    ];

    const handleOk = async () => {
        const newRoles = selectedRoles.filter(role => !originalRoles?.getUserRoles.includes(role));
        const rolesTBD = originalRoles?.getUserRoles.filter(role => !selectedRoles.includes(role));
        if (newRoles.length) {
            await assignRole({variables: {user: users?.customers[selectedUser]?.email, roles: newRoles}})
        }
        if (rolesTBD.length) {
            await deleteRole({variables: {user: users?.customers[selectedUser]?.email, roles: rolesTBD}})
        }
        setModalOpen(false);
    }

    return <Flex vertical={true} gap={"middle"}>
        <Row justify={"end"}>
            <Button type={"primary"} onClick={() => setVisible(true)} icon={<PlusOutlined/>}> Aggiungi utente</Button>
        </Row>
        <Modal title={`Seleziona un ruolo per ${users?.customers[selectedUser]?.email}`}
               open={isModalOpen}
               onOk={handleOk}
               onCancel={() => {
                   setSelectedRoles([]);
                   setModalOpen(false);
                   setSelectedUser(null);
               }}
               confirmLoading={assignRoleLoading || deleteRoleLoading}
               okButtonProps={{
                   disabled: selectedRoles.length === originalRoles?.getUserRoles?.length &&
                       selectedRoles.filter(role => !originalRoles?.getUserRoles.includes(role)).length === 0
               }}
        >
            <Select
                showSearch
                mode="multiple"
                allowClear
                style={{width: '100%'}}
                placeholder="Seleziona ruolo"
                onChange={setSelectedRoles}
                value={selectedRoles}
                options={allRoles?.roles ? allRoles.roles.filter(role => !selectedRoles.includes(role.name)).map(role => {
                    return {value: role.name, label: role.description, key: role.name}
                }) : []}
            />
        </Modal>
        <Table
            dataSource={users?.customers?.map((user, idx) => {
                return {key: idx.toString(), user: user.email, businessName: user.businessName}
            })}
            columns={columns}
            bordered={true}
            size={"small"}
            pagination={{pageSize: 10}}
            loading={loading || deleteUserLoading}
        />
        <Drawer
            title={"Aggiungi utente"}
            size={'large'}
            onClose={() => {
                setVisible(false);
            }}
            open={visible}
        >
            <UserRegistrationForm
                refetch={refetch}
                roles={allRoles?.roles ? allRoles.roles : []}
            />
        </Drawer>
    </Flex>;
};

export default UserTable;
