import {Button, Col, Divider, Flex, Form, Input, Row, Select, Typography} from "antd";
import TextArea from "antd/lib/input/TextArea";
import {CheckCircleOutlined} from "@ant-design/icons";
import {useLazyQuery, useMutation} from "@apollo/client";
import {CHANGE_PASSWORD, CUSTOMER_QUERY, UPDATE_CUSTOMER} from "../apollo/apollo-queries";
import {useAuth0} from "@auth0/auth0-react";
import {useContext, useEffect} from "react";
import {NotificationsContext} from "../components/common/NotificationsContext";

export const {Title, Text} = Typography;

export const Account = () => {
    const {notifications} = useContext(NotificationsContext)
    const {user} = useAuth0();
    const [form] = Form.useForm();
    const [updateUser] = useMutation(UPDATE_CUSTOMER, {
        onCompleted: async () => {
            notifications.success("Dati aggiornati!")
            await refetchAccount();
        },
        onError: (error => {
            notifications.error(`Errore nel server: ${error.message}`)
        }),
    });

    const [changePassword] = useLazyQuery(CHANGE_PASSWORD, {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
        onError: error => {
            notifications.error(`Errore nel server: ${error.message}`)
        },
    });

    const [fetchAccount, {
        refetch: refetchAccount
    }] = useLazyQuery(CUSTOMER_QUERY, {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
        onError: error => {
            notifications.error(`Errore nel server: ${error.message}`)
        },
        onCompleted: (data) => {
            form.setFieldsValue(data.customer)
        }
    });

    useEffect(() => {
        fetchAccount({variables: {mail: user.email}})
    }, [fetchAccount, user.email]);

    return <Flex vertical gap={"large"}>
        <Title level={3}>Il mio account</Title>
        <Form
            layout={"vertical"}
            form={form}
            onFinish={async (values) => {
                if (values.newPassword && values.newPassword !== '') {
                    if (values.newPassword === values.repeatPassword) {
                        //change pass
                        await changePassword({variables: {mail: user.email, newPassword: values.newPassword}})
                    }
                }
                const changes = Object.keys(values)
                    .filter(key => key !== 'newPassword' && key !== 'repeatPassword' && values[key] !== '')
                    .reduce((acc, key) => {
                        acc[key] = values[key]
                        return acc;
                    }, {});
                await updateUser({
                    variables: {customer: {...changes, email: user.email}}
                })
                ;
            }}
        >
            <Divider orientation={"left"}>Dati anagrafici</Divider>
            <Row gutter={16}>
                <Col span={12}>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Nome" required name={'name'}>
                                <Input placeholder="Mario"/>
                            </Form.Item>

                        </Col>
                        <Col span={12}>
                            <Form.Item label="Cognome" required name={'surname'}>
                                <Input placeholder="Rossi"/>
                            </Form.Item>

                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Telefono" required name={'phone'}>
                                <Input placeholder="1234567890"/>
                            </Form.Item>

                        </Col>
                        <Col span={12}>
                            <Form.Item label="SDI" required name={'sdi'}>
                                <Input placeholder="SDI"/>
                            </Form.Item>

                        </Col>
                    </Row>
                </Col>
                <Col span={12}>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Ragione sociale" required name={'businessName'}>
                                <Input placeholder="Mario Rossi spa"/>
                            </Form.Item>

                        </Col>
                        <Col span={12}>
                            <Form.Item label="Partita iva" required name={'fiscalCode'}>
                                <Input placeholder="0000000000"/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item label="Note eventuali per il fornitore" name={'notes'}>
                        <TextArea placeholder="Note"/>
                    </Form.Item>
                </Col>
            </Row>
            <Divider orientation={"left"}>Indirizzo principale</Divider>
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item label="Via" required={true} name={'street'}>
                        <Input placeholder="via"/>
                    </Form.Item>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Civico" required name={'number'}>
                                <Input placeholder="Civico"/>
                            </Form.Item>

                        </Col>
                        <Col span={12}>
                            <Form.Item label="CAP" required name={'postalCode'}>
                                <Input placeholder="CAP"/>
                            </Form.Item>

                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Comune" required name={'city'}>
                                <Input placeholder="Comune"/>
                            </Form.Item>

                        </Col>
                        <Col span={12}>
                            <Form.Item label="Provincia" required name={'state'}>
                                <Input placeholder="Provincia"/>
                            </Form.Item>

                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row gutter={16} justify={'start'}>
                <Form.Item label={'Indirizzo estero'} name={'foreign'}>
                    <Select options={[{value: true, label:'Si'}, {value: false, label:'No'}, ]}/>
                </Form.Item>
            </Row>
            <Divider orientation={"left"}>Reset password</Divider>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item label="Nuova password" name={'newPassword'}>
                        <Input.Password placeholder="input password"/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Ripeti" name={'repeatPassword'}>
                        <Input.Password placeholder="input password"/>
                    </Form.Item>
                </Col>
            </Row>

            <Row justify={"end"}>
                <Form.Item>
                    <Button icon={<CheckCircleOutlined/>} type="primary" htmlType={"submit"}>Aggiorna i dati</Button>
                </Form.Item>
            </Row>
        </Form>
    </Flex>

}