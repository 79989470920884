import {createSlice} from '@reduxjs/toolkit';

export const wheelsSlice = createSlice({
    name: 'wheels',
    initialState: {
        data: [],
        filter: {
            offset: 0,
            limit: 6,
            filters: {
                OnlyInternal: false,
                Brand: '',
                Width: '',
                MinOffset: '',
                MaxOffset: '',
                Finish: '',
                CenterBore: '',
                Diameter: '',
                BoltPattern: '',
                PartNumber: '',
                Beadlock: false
            },
            sort: {key: "InternalQOH", order: -1}
        },
        pagination: {
            defaultPageSize: 6,
            pageSizeOptions: [6, 10, 50],
        },
    },
    reducers: {
        setData: (state, action) => {
            state.data = action.payload;
        },
        setFilter: (state, action) => {
            state.filter = action.payload;
        },
        setTablePagination: (state, action) => {
            state.pagination = action.payload;
        },
    },
});

export const {setData, setFilter, setTablePagination} = wheelsSlice.actions;

export default wheelsSlice.reducer;
