import React, {useContext, useEffect, useRef, useState} from 'react';
import {Button, Flex, Form, Input, Row, Table} from 'antd';
import {useMutation, useQuery} from "@apollo/client";
import {ADD_WHEELS_SC, EDIT_WHEELS_SC, WHEELS_SC_QUERY} from "../../apollo/apollo-queries";
import {NotificationsContext} from "../common/NotificationsContext";

const EditableContext = React.createContext(null);
const EditableRow = ({index, ...props}) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};
const EditableCell = ({
                          title,
                          editable,
                          children,
                          dataIndex,
                          record,
                          handleSave,
                          ...restProps
                      }) => {
    const {notifications} = useContext(NotificationsContext)
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    useEffect(() => {
        if (editing) {
            inputRef.current.focus();
        }
    }, [editing]);
    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({
            [dataIndex]: record[dataIndex],
        });
    };
    const save = async () => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            handleSave({
                ...record,
                ...values,
            });
        } catch (errInfo) {
            notifications.error('Save failed:', errInfo);
        }
    };
    let childNode = children;
    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{
                    margin: 0,
                }}
                name={dataIndex}
                rules={[
                    {
                        required: true,
                        message: `${title} is required.`,
                    },
                ]}
            >
                <Input ref={inputRef} onPressEnter={save} onBlur={save}/>
            </Form.Item>
        ) : (
            <div
                className="editable-cell-value-wrap"
                style={{
                    paddingRight: 24,
                }}
                onClick={toggleEdit}
            >
                {children}
            </div>
        );
    }
    return <td {...restProps}>{childNode}</td>;
};
const WheelShipmentCosts = () => {
    const {notifications} = useContext(NotificationsContext)
    const {
        data: originalDataSource, loading: loadingSC, refetch: refetchOriginalDataSource
    } = useQuery(WHEELS_SC_QUERY, {
        errorPolicy: "all",
        onCompleted: (data) => {
            setDataSource(data?.getWheelExtraPrices.map((item, idx) => {
                return {...item, key: idx}
            }))
            setCount(data?.getWheelExtraPrices.length)
        }
    });
    const [addSC, {
        loading: addSCLoading
    }] = useMutation(ADD_WHEELS_SC, {
        errorPolicy: "all",
        onCompleted: async (data) => {
            await refetchOriginalDataSource();
        },
        onError: error => {
            notifications.error(error.message)
        }
    });
    const [editSC, {
        loading: editSCLoading
    }] = useMutation(EDIT_WHEELS_SC, {
        errorPolicy: "all",
        onCompleted: async (data) => {
            await refetchOriginalDataSource();
        },
        onError: error => {
            notifications.error(error.message)
        }
    });

    const [dataSource, setDataSource] = useState([]);
    const [count, setCount] = useState(0);
    const [canSave, setCanSave] = useState(false);

    const defaultColumns = [
        {
            title: 'Diametro',
            dataIndex: 'diameter',
            width: '33%',
            editable: true,
            sorter: ({diameter : a}, {diameter : b}) => {
                return parseFloat(a) < parseFloat(b) ? 1 : -1
            },
            sortDirections: ['ascend', 'descend'],
            defaultSortOrder: 'descend',
        },
        {
            title: 'Costo US',
            dataIndex: 'valueUS',
            width: '33%',
            editable: true,
        },
        {
            title: 'Costo 4-5 gg',
            dataIndex: 'valueUE',
            width: '33%',
            editable: true,
        },
    ];

    const handleAdd = () => {
        const newData = {
            key: count,
            diameter: 'Click to edit',
            valueUS: 'Click to edit',
            valueUE: 'Click to edit',
        };
        setDataSource([...dataSource, newData]);
        setCount(count + 1);
    };

    const handleSaveCell = (row) => {
        const newData = [...dataSource];
        const index = newData.findIndex((item) => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row,
        });
        setDataSource(newData);
    };

    const handleSave = async () => {
        await Promise.all(originalDataSource?.getWheelExtraPrices.map(async (item, idx) => {
            const compItem = {
                diameter: dataSource[idx]['diameter'],
                valueUS: dataSource[idx]['valueUS'],
                valueUE: dataSource[idx]['valueUE']
            };

            if (JSON.stringify(compItem) !== JSON.stringify(item)) {
                Object.keys(compItem).forEach(key => compItem[key] = parseFloat(compItem[key]));
                await editSC({variables: {extraPrice: compItem}})
            }
        }))


        const newItems = dataSource.splice(originalDataSource?.getWheelExtraPrices.length, count).map(item => {
            return {
                diameter: parseFloat(item['diameter']),
                valueUS: parseFloat(item['valueUS']),
                valueUE: parseFloat(item['valueUE'])
            };
        });

        if (newItems.length > 0) {
            await addSC({variables: {extraPrices: newItems}});
        }
    }

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    const columns = defaultColumns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: handleSaveCell,
            }),
        };
    });

    useEffect(() => {
        const odWithIndexes = originalDataSource?.getWheelExtraPrices.map((item, idx) => {
            return {...item, key: idx}
        })
        if (JSON.stringify(odWithIndexes) !== JSON.stringify(dataSource)) {
            setCanSave(true);
        } else {
            setCanSave(false)
        }
    }, [dataSource, originalDataSource, originalDataSource?.getWheelExtraPrices])
    return (
        <Flex vertical={true} gap={"middle"}>
            <Row justify={"end"}>
                <Flex gap={"middle"}>
                    <Button
                        onClick={handleAdd}
                        type="primary"
                    >
                        Aggiungi riga
                    </Button>
                    <Button
                        onClick={() => {
                            setDataSource(originalDataSource?.getWheelExtraPrices.map((item, idx) => {
                                return {...item, key: idx}
                            }))
                            setCount(originalDataSource?.getWheelExtraPrices.length)
                        }}
                        type="dashed"
                        danger={true}
                    >
                        Reset
                    </Button>
                    <Button
                        loading={addSCLoading || editSCLoading}
                        onClick={handleSave}
                        type="primary"
                        className={'ms-3'}
                        disabled={!canSave}
                    >
                        Salva
                    </Button>
                </Flex>

            </Row>
            <div>
                <Table
                    size={"small"}
                    loading={loadingSC}
                    components={components}
                    rowClassName={() => 'editable-row'}
                    bordered
                    dataSource={dataSource}
                    columns={columns}
                />
            </div>
        </Flex>
    );
};
export default WheelShipmentCosts;
