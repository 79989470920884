import React, {Fragment} from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import {Auth0Provider} from "@auth0/auth0-react";
import {getConfig} from "./config";
import {AuthorizedApolloProvider} from "./apollo/authorizedApolloProvider"
import 'bootstrap/dist/css/bootstrap-grid.css'
import 'bootstrap/dist/css/bootstrap-utilities.css'
import {BrowserRouter} from "react-router-dom";
import {CartCounterContextProvider} from "./components/cart/CartCounterContext";
import {ConfigProvider} from "antd";
import {NotificationsProvider} from "./components/common/NotificationsContext";
import {DiscountsProvider} from "./components/common/DiscountContext";
import {NewOrdersCounterContextProvider} from "./components/orders/NewOrdersCounterContext";
import {Provider} from "react-redux";
import store from "./redux/store";
import {isChromeOrOpera} from "./components/common/utils";
import {Redirect} from "./views/Redirect";

const config = getConfig();

const providerConfig = {
    domain: config.domain,
    clientId: config.clientId,
    ...(config.audience ? {audience: config.audience} : null),
    redirectUri: window.location.origin,
};
document.getElementById('root').style.height = '100%'

if (isChromeOrOpera()) {
    ReactDOM.render(
        <Auth0Provider {...providerConfig}>
            <NotificationsProvider>
                <AuthorizedApolloProvider>
                    <DiscountsProvider>
                        <BrowserRouter>
                            <NewOrdersCounterContextProvider>
                                <CartCounterContextProvider>
                                    <ConfigProvider theme={{
                                        "token": {
                                            "colorPrimary": "#d03033",
                                            "colorInfo": "#d03033"
                                        },
                                        "components": {
                                            "Menu": {
                                                "darkItemBg": "rgb(64, 64, 64)",
                                                "darkItemSelectedBg": "rgb(128, 104, 104)"
                                            },
                                            "Layout": {
                                                "siderBg": "rgb(64, 64, 64)"
                                            }
                                        }
                                    }}>
                                        <Provider store={store}>
                                            <App/>
                                        </Provider>
                                    </ConfigProvider>
                                </CartCounterContextProvider>
                            </NewOrdersCounterContextProvider>
                        </BrowserRouter>
                    </DiscountsProvider>
                </AuthorizedApolloProvider>
            </NotificationsProvider>
        </Auth0Provider>,
        document.getElementById("root")
    );
} else {
    ReactDOM.render(
        <Redirect/>,
        document.getElementById("root"));

}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
