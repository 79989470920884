import {Button, Col, Drawer, Flex, Image, InputNumber, List, Popconfirm, Row, Typography} from "antd";
import {Text, Title} from "../../views/Home";
import {beautifyNumber} from "../common/utils";
import {PlusIcon} from "@primer/octicons-react";
import {useNavigate} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import {DiscountsContext} from "../common/DiscountContext";
import {useAuth0} from "@auth0/auth0-react";
import placeholder from "../../assets/placeholder.jpg";

export const WheelDetail = ({
                                selectedArticle,
                                visible,
                                setVisible,
                                setSelectedArticle,
                                setSelectedArticleQuantity,
                                articleDetailVoices,
                                selectedArticleQuantity,
                                setItems,
                                showDiscount
                            }) => {
    const navigate = useNavigate();
    const {getDiscount} = useContext(DiscountsContext)

    const addToCart = () => {
        const newArticles = selectedArticleQuantity
            .filter(({quantity}) => quantity)
            .map((item, idx) => {
                return {
                    code: selectedArticle.PartNumber,
                    description: selectedArticle.PartDescription,
                    brand: selectedArticle.Brand,
                    category: 'cerchi',
                    deliveryOption: item.key === 'InternalQOH' ? 0 : item.key === 'EuropeQOH' ? 1 : 2,
                    unitPrice: item.getPrice(selectedArticle.MSRP_EUR, discount),
                    quantity: item.quantity,
                }
            })

        setItems(prev => [...prev, ...newArticles])
    }
    const [discount, setDiscount] = useState(0);
    const {user} = useAuth0();

    useEffect(() => {
        if (user && selectedArticle?.Brand) {
            if (!user["https://magnus-store.com/roles"].includes('admin')) {
                setDiscount(getDiscount('cerchi', selectedArticle.Brand))
            }
        }
    }, [getDiscount, selectedArticle.Brand, user]);

    return <Drawer
        title={selectedArticle?.PartNumber}
        size={'large'}
        onClose={() => {
            setVisible(false)
            setSelectedArticle({})
            setSelectedArticleQuantity([])
        }}
        open={visible}>
        <Flex vertical={true} className={'h-100'}>
            <Row>
                <Col span={12}><Image
                    width={200}
                    src={selectedArticle.ImageURL}
                    alt={placeholder}
                /></Col>
                <Col span={12}>
                    <List
                        size={'small'}
                        bordered={false}
                        dataSource={Object.keys(articleDetailVoices)}
                        renderItem={(item) => (
                            <List.Item>
                                <Typography.Text
                                    strong={true}>{articleDetailVoices[item]}:</Typography.Text> {selectedArticle[item]}
                            </List.Item>
                        )}
                    >

                    </List>
                </Col>
            </Row>
            <Flex vertical={true} style={{flexGrow: 1}} justify={"end"}>
                <List
                    header={
                        <Flex vertical={true}>
                            <Title level={5}>Opzioni disponibilità</Title>
                            <span
                                style={{color: "##7C7C7C"}}>Seleziona le opzioni di consegna e la relativa quantità</span>
                        </Flex>
                    }
                    footer={
                        <Row align={'middle'}>
                            <Col span={12}>
                                <Row justify={"start"}>
                                    Prezzo totale:
                                    <Text strong>
                                        {
                                            beautifyNumber(selectedArticleQuantity.reduce((acc, item) => {
                                                return acc + item.getPrice(selectedArticle.MSRP_EUR, discount).total * item.quantity
                                            }, 0))
                                        } €
                                    </Text>
                                </Row>
                            </Col>
                            <Col span={12}>
                                <Row justify={"end"}>
                                    <Popconfirm
                                        title="Vuoi continuare lo shopping?"
                                        onConfirm={() => {
                                            addToCart();
                                            setVisible(false);
                                        }}
                                        onCancel={() => {
                                            addToCart();
                                            navigate('/carrello');
                                        }}
                                        okText="Continua lo shopping"
                                        cancelText="Vai al carrello"
                                    >
                                        <Button
                                            type="primary"
                                            htmlType="button"
                                            icon={<PlusIcon/>}
                                            disabled={selectedArticleQuantity.every(item => item.quantity === 0) || selectedArticleQuantity.some(item => item.quantity > parseInt(selectedArticle[item.key]))}
                                        >
                                            Aggiungi al carrello
                                        </Button>
                                    </Popconfirm>
                                </Row>
                            </Col>
                        </Row>
                    }
                    size={'small'}
                    bordered={false}
                    dataSource={selectedArticleQuantity}
                    renderItem={(item) => {
                        return <Row align={'middle'} className={'filter-form mb-1'}>
                            <Col span={12}>
                                <Flex vertical={true}>
                                    {item.label}
                                    {
                                        (discount && showDiscount) ? <Flex gap={'small'}>
                                                <Text>
                                                    <del>
                                                        {beautifyNumber(item.getPrice(selectedArticle.MSRP_EUR, 0).total)}€
                                                        cad.
                                                    </del>
                                                </Text>
                                                <Text strong>
                                                    {beautifyNumber(item.getPrice(selectedArticle.MSRP_EUR, discount).total)}€
                                                    cad.
                                                </Text>
                                            </Flex> :
                                            <Text strong>
                                                {beautifyNumber(item.getPrice(selectedArticle.MSRP_EUR, 0).total)}€ cad.
                                            </Text>
                                    }
                                </Flex>
                            </Col>
                            <Col span={6}>
                                Disponibili: {selectedArticle[item.key]}
                            </Col>
                            <Col span={6}>
                                <InputNumber
                                    min={0}
                                    max={selectedArticle[item.key]}
                                    defaultValue={item.quantity}
                                    disabled={selectedArticle[item.key] === 0}
                                    onChange={(value) => {
                                        setSelectedArticleQuantity(prev => {
                                            return prev.map((innerItem) => item.key === innerItem.key ? {
                                                ...innerItem,
                                                quantity: value
                                            } : innerItem)
                                        })
                                    }}
                                />
                            </Col>
                        </Row>
                    }}
                >
                </List>
            </Flex>
        </Flex>
    </Drawer>
}