import {Flex, Image} from "antd";
import logo from "../assets/magnus.png"
import {Text} from "./Home";

export const Redirect = () => {
    return <Flex vertical={true} align={"center"} justify={"center"}>
        <Image style={{backgroundColor: 'lightgray'}} src={logo} alt={"Magnus logo"}/>
        <Text> Gentile cliente, l'applicazione può essere raggiunta solo tramite Chrome o Opera.</Text>
    </Flex>
}
