import {Alert, Col, Flex, Row, Switch,} from "antd";
import FilterForm from "../components/wheels/WheelsFilters";
import {useQuery} from "@apollo/client";
import {WHEELS_FILTERS_QUERY, WHEELS_QUERY, WHEELS_SC_QUERY} from "../apollo/apollo-queries";
import WheelsTable from "../components/wheels/WheelsTable";
import {useContext, useState} from "react";
import {Title} from "./Home";
import {CartCounterContext} from "../components/cart/CartCounterContext";
import {WheelDetail} from "../components/wheels/WheelDetail";
import {NotificationsContext} from "../components/common/NotificationsContext";
import {useDispatch, useSelector} from 'react-redux';
import {setData, setFilter, setTablePagination} from "../redux/wheelsSlice";

export const Wheels = () => {
    const {notifications} = useContext(NotificationsContext)
    const [visible, setVisible] = useState(false);
    const [selectedArticle, setSelectedArticle] = useState({});
    const [selectedArticleQuantity, setSelectedArticleQuantity] = useState([]);
    const [showDiscount, setShowDiscount] = useState(true);
    const {setItems} = useContext(CartCounterContext);
    const wheels = useSelector((state) => state.wheels.data);
    const filter = useSelector((state) => state.wheels.filter);
    const pagination = useSelector((state) => state.wheels.pagination);
    const dispatch = useDispatch();

    //<editor-fold desc="filters">
    const {
        data: filters
    } = useQuery(WHEELS_FILTERS_QUERY, {
        errorPolicy: "all",
        onError: error => {
            notifications.error(`Errore nel server: ${error.message}`)
        }
    });
    //</editor-fold>

    //<editor-fold desc="wheels">
    const {
        loading: wheelsLoading,
        refetch: refetchWheels
    } = useQuery(WHEELS_QUERY, {
        variables: filter,
        errorPolicy: "all",
        onError: error => {
            notifications.error(`Errore nel server: ${error.message}`)
        },
        onCompleted: (data) => {
            dispatch(setData(data))
            dispatch(setTablePagination({...pagination, total: data.getWheels.totalDocuments}))
        }
    });
    //</editor-fold>\

    //<editor-fold desc="wheelsOverprices">
    const {
        data: wheelOPs
    } = useQuery(WHEELS_SC_QUERY, {
        errorPolicy: "all",
        onError: error => {
            notifications.error(`Errore nel server: ${error.message}`)
        }
    });

    //</editor-fold>\

    const handleShowArticle = (article) => {
        setVisible(true);
        setSelectedArticle(article);
        setSelectedArticleQuantity([
            {
                key: 'InternalQOH',
                label: 'Disponibilità 24/48 ore',
                overprice: 0,
                quantity: 0,
                getPrice: (base, discount = 0) => {
                    return {
                        base,
                        overprice: 0,
                        overpriceUS: 0,
                        discount,
                        total: Math.round(base * (100 - discount)) / 100
                    };
                }
            },
            {
                key: 'EuropeQOH',
                label: 'Disponibilità 4/5 gg',
                quantity: 0,
                getPrice: (base, discount = 0) => {
                    return {
                        base,
                        overprice: getWheelOverprice(article?.Size?.split("X")[0], 'valueUE'),
                        overpriceUS: 0,
                        discount,
                        total: Math.round(base * (100 - discount)) / 100 + getWheelOverprice(article?.Size?.split("X")[0], 'valueUE')
                    };
                }
            },
            {
                key: 'AmericaQOH',
                label: 'Disponibilità US',
                quantity: 0,
                getPrice: (base, discount = 0) => {
                    return {
                        base,
                        overprice: getWheelOverprice(article?.Size?.split("X")[0], 'valueUS'),
                        discount,
                        overpriceUS: (Math.round(base * (15)) / 100),
                        total: (Math.round(base * (15)) / 100) + (Math.round(base * (100 - discount)) / 100) + getWheelOverprice(article?.Size?.split("X")[0], 'valueUS')
                    };

                }
            }
        ])
    }

    const articleDetailVoices = {
        "Style": "Stile",
        "Size": "Misure",
        "BoltPattern": "Bolt pattern",
        "Offset": "Offset",
        "CenterBore": "Foro centrale",
        "LoadRating": "Load rating",
        "Finish": "Finitura",
        "Brand": "Brand",
    }

    const handleTableChange = async (currentPagination, _, sorter) => {
        let sort = {
            key: sorter.columnKey,
            order: sorter.order === 'ascend' ? 1 : sorter.order === 'descend' ? -1 : undefined
        }

        dispatch(setTablePagination({...pagination, ...currentPagination}))
        dispatch(setFilter({
            ...filter,
            offset: currentPagination.pageSize * (currentPagination.current - 1),
            limit: (currentPagination.pageSize * (currentPagination.current - 1)) + currentPagination.pageSize,
            sort
        }))
        await refetchWheels({
            sort,
            offset: currentPagination.pageSize * (currentPagination.current - 1),
            limit: (currentPagination.pageSize * (currentPagination.current - 1)) + currentPagination.pageSize
        })
    }

    const getWheelOverprice = (diameter, key) => {
        if (wheelOPs) {
            const match = wheelOPs.getWheelExtraPrices.find((op) => {
                return op.diameter === parseInt(diameter)
            });
            if (match)
                return match[key];
        }
        return 0;
    }

    return <Flex vertical gap={'large'}>
        <Row align={'middle'}>
            <Col span={12}>
                <Title level={3}>Cerchi</Title>
            </Col>
            <Col span={12}>
                <Row align={'middle'}>
                    <Col span={12} offset={12}>
                        <Switch defaultChecked onChange={setShowDiscount} checked={showDiscount}/> Mostra prezzi
                        scontati
                    </Col>
                </Row>
            </Col>
        </Row>
        <FilterForm
            filters={filters?.getWheelsFilters}
            onSubmit={refetchWheels}
        ></FilterForm>
        {/*<Alert type={"info"} message={"Qui vanno scritti eventuali disclaimer, alert "}></Alert>*/}
        <WheelsTable
            wheels={wheels ? wheels : []}
            wheelsLoading={wheelsLoading}
            handleTableChange={handleTableChange}
            handleShowArticle={handleShowArticle}
            showDiscount={showDiscount}
        ></WheelsTable>
        <WheelDetail
            selectedArticle={selectedArticle}
            visible={visible}
            setVisible={setVisible}
            setSelectedArticle={setSelectedArticle}
            setSelectedArticleQuantity={setSelectedArticleQuantity}
            articleDetailVoices={articleDetailVoices}
            selectedArticleQuantity={selectedArticleQuantity}
            setItems={setItems}
            showDiscount={showDiscount}
        ></WheelDetail>
    </Flex>

}