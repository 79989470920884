import {withAuthenticationRequired} from "@auth0/auth0-react";
import React from "react";
import {LoadingOutlined} from "@ant-design/icons";

export const AuthenticationGuard = ({ component }) => {
    const Component = withAuthenticationRequired(component, {
        onRedirecting: () => (
            <div style={{width: '100%', height: '100%'}}>
                <LoadingOutlined/>
            </div>
        ),
    });

    return <Component />;
};
