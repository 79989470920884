import React, {Fragment, useContext, useEffect, useState} from 'react';
import './index.css';
import {LoginOutlined, LogoutOutlined, ShoppingCartOutlined, UserOutlined,} from '@ant-design/icons';
import {Badge, Button, Col, Flex, Layout, Menu, Result, Row, Space, theme} from 'antd';
import fullLogo from "./assets/magnus.png"
import {useAuth0} from "@auth0/auth0-react";
import {ListUnorderedIcon} from "@primer/octicons-react";
import {Outlet, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {Home, Text} from "./views/Home";
import {Wheels} from "./views/Wheels";
import {Cart} from "./views/Cart";
import {CartCounterContext} from "./components/cart/CartCounterContext";
import {Orders} from "./views/Orders";
import {OrderDetail} from "./views/OrderDetail";
import {Account} from "./views/Account";
import {AuthenticationGuard} from "./components/common/authentication-guard";
import {Catalogs} from "./views/Catalogs";
import {Mappings} from "./views/Mappings";
import {Stock} from "./views/Stock";
import {NewCatalog} from "./views/NewCatalog";
import AddMapping from "./views/AddMapping";
import {Globals} from "./views/Globals";
import {Discounts} from "./views/Discounts";
import {UserManagement} from "./views/UserManagement";
import {Accessories} from "./views/Accessories";
import {NewOrdersCounterContext} from "./components/orders/NewOrdersCounterContext";
import {Lubricants} from "./views/Lubricants";
import {RaptorPaints} from "./views/RaptorPaints";
import {Parts} from "./views/Parts";

const {Header, Sider, Content} = Layout;

const App = () => {
    const {
        token: {
            colorBgContainer,
            borderRadiusLG,

        },
    } = theme.useToken();
    const {items: cartItems} = useContext(CartCounterContext);
    const {newOrders} = useContext(NewOrdersCounterContext);

    const [current, setCurrent] = useState('');
    const [sideMenuItems, setSideMenuItems] = useState([])
    const [menuItems, setMenuItems] = useState([]);
    const navigate = useNavigate();
    const location = useLocation()

    const {
        isAuthenticated,
        loginWithRedirect,
        logout,
        user
    } = useAuth0();


    const logoutWithRedirect = () =>
        logout({
            returnTo: window.location.origin,
        });

    const onClickMenuItem = (e) => {
        setCurrent(e.key);
        navigate(e.key);
        setCurrent(e.key)
    };

    useEffect(() => {
        setCurrent(location.pathname.split("/")[1])
    }, [location.pathname]);


    useEffect(() => {
        const sideItems = [
            {
                key: '',
                label: 'Tutte le categorie',
            },
        ]

        if (user && user["https://magnus-store.com/roles"].includes('accessory_client')) {
            sideItems.push({
                key: 'accessori',
                label: 'Accessori',
            })
        }
        if (user && user["https://magnus-store.com/roles"].includes('lubricants_client')) {
            sideItems.push({
                key: 'lubrificanti',
                label: 'Lubrificanti',
            })
        }
        if (user && user["https://magnus-store.com/roles"].includes('paints_client')) {
            sideItems.push({
                key: 'vernici-raptor',
                label: 'Vernici Raptor',
            })
        }
        if (user && user["https://magnus-store.com/roles"].includes('parts_client')) {
            sideItems.push({
                key: 'ricambi',
                label: 'Ricambi',
            })

        }
        if (user && user["https://magnus-store.com/roles"].includes('wheel_client')) {
            sideItems.push({
                key: 'cerchi',
                label: 'Cerchi',
            })
        }


        if (user && user["https://magnus-store.com/roles"].includes('admin')) {
            setMenuItems([
                {
                    label: <Space>
                        <Text>Carrello</Text>
                        <Badge color={"red"} count={cartItems.length} showZero={true}/>
                    </Space>,
                    key: 'carrello',
                    icon: <ShoppingCartOutlined/>,
                },
                {
                    label: <Space>
                        <Text>Ordini</Text>
                        <Badge color={"red"} count={newOrders} showZero={true}/>
                    </Space>,
                    key: 'ordini',
                    icon: <ListUnorderedIcon/>,
                }])
            setSideMenuItems([...sideItems, {
                key: 'backoffice',
                label: 'Backoffice',
                children: [
                    {
                        key: 'cataloghi',
                        label: 'Stock & Cataloghi',
                        type: 'group',
                        children: [
                            {
                                key: 'catalogs',
                                label: 'Cataloghi'
                            },
                            {
                                key: 'mappings',
                                label: 'Mappature'
                            },
                            {
                                key: 'stock',
                                label: 'Stock'
                            },
                        ],
                    },
                    {
                        key: 'management',
                        label: 'Gestione',
                        type: 'group',
                        children: [
                            {
                                key: 'globals',
                                label: 'Variabili Globali'
                            },
                            {
                                key: 'user-management',
                                label: 'Gestione Utenti'
                            },
                            {
                                key: 'discounts',
                                label: 'Scontistica'
                            },
                        ],
                    },
                ],
            }])

        } else {
            setSideMenuItems(sideItems)
            setMenuItems([{
                label: <Space><Text>Carrello</Text><Badge color={"red"} count={cartItems.length}
                                                          showZero={true}></Badge></Space>,
                key: 'carrello',
                icon: <ShoppingCartOutlined/>,
            },
                {
                    label: 'I miei ordini',
                    key: 'ordini',
                    icon: <ListUnorderedIcon/>,
                },
                {
                    label: 'Account',
                    key: 'account',
                    icon: <UserOutlined/>,
                }])
        }
    }, [cartItems.length, newOrders, user]);

    return (
        <Routes>
            <Route
                path="/"
                element={
                    <Layout className={'h-100'}>
                        <Sider trigger={null}>
                            <div
                                style={{
                                    height: 32,
                                    margin: 20,
                                    backgroundImage: `url(${fullLogo})`,
                                    backgroundSize: 'contain',
                                    backgroundRepeat: 'no-repeat'
                                }}
                            />
                            <Menu
                                theme="dark"
                                mode="inline"
                                selectedKeys={[current]}
                                onClick={onClickMenuItem}
                                items={sideMenuItems}
                            />
                        </Sider>
                        <Layout>
                            <Header
                                style={{
                                    background: colorBgContainer,
                                }}
                            >
                                {
                                    isAuthenticated && <Row align={"middle"}>
                                        <Col
                                            span={user && user["https://magnus-store.com/roles"].includes('admin') ? 18 : 16}>
                                            <Row justify={'middle'}>
                                                <Text>Benvenuto, {isAuthenticated && user && user.name}</Text>
                                                <Button className={'ms-2'} type={'link'} onClick={logoutWithRedirect}
                                                        icon={<LogoutOutlined/>}>
                                                    Logout
                                                </Button>
                                            </Row>
                                        </Col>
                                        <Col
                                            span={user && user["https://magnus-store.com/roles"].includes('admin') ? 6 : 8}>
                                            <Flex className={'w-100'} justify={"end"}>
                                                <Menu onClick={onClickMenuItem}
                                                      mode={'horizontal'}
                                                      selectedKeys={[current]}
                                                      items={menuItems}
                                                      style={{minWidth: 0, flex: "auto"}}
                                                />
                                            </Flex>
                                        </Col>
                                    </Row>
                                }
                                {
                                    !isAuthenticated && <Row align={'middle'} justify={"end"} style={{height: '100%'}}>
                                        <Button type={"primary"} onClick={loginWithRedirect}
                                                icon={<LoginOutlined/>}>Login</Button>
                                    </Row>
                                }
                            </Header>
                            <Content
                                style={{
                                    paddingLeft: 40,
                                    paddingRight: 40,
                                    paddingBottom: 24,
                                    minHeight: 280,
                                    background: '#F3F3F3',
                                    borderRadius: borderRadiusLG,
                                    overflow: "scroll"
                                }}
                            >
                                <Outlet/>
                            </Content>
                        </Layout>
                    </Layout>
                }
            >
                <Route index element={<AuthenticationGuard component={Home}/>}/>
                <Route path="/cerchi" element={<AuthenticationGuard component={Wheels}/>}/>
                <Route path="/carrello" element={<AuthenticationGuard component={Cart}/>}/>
                <Route path="/ordini" element={<AuthenticationGuard component={Orders}/>}/>
                <Route path="/ordini/:id" element={<AuthenticationGuard component={OrderDetail}/>}/>
                <Route path="/account" element={<AuthenticationGuard component={Account}/>}/>
                <Route path={"/accessori"} element={<AuthenticationGuard component={Accessories}/>}/>
                <Route path={"/ricambi"} element={<AuthenticationGuard component={Parts}/>}/>
                <Route path={"/lubrificanti"} element={<AuthenticationGuard component={Lubricants}/>}/>
                <Route path={"/vernici-raptor"} element={<AuthenticationGuard component={RaptorPaints}/>}/>
                <Route path={"catalogs/new-catalog"} element={<AuthenticationGuard component={NewCatalog}/>}/>
                <Route path={"/mappings/new-mapping"} element={<AuthenticationGuard component={AddMapping}/>}/>
                <Route path={"/catalogs"} element={<AuthenticationGuard component={Catalogs}/>}/>
                <Route path={"/mappings"} element={<AuthenticationGuard component={Mappings}/>}/>
                <Route path={"/stock"} element={<AuthenticationGuard component={Stock}/>}/>
                <Route path={"/globals"} element={<AuthenticationGuard component={Globals}/>}/>
                <Route path={"/discounts"} element={<AuthenticationGuard component={Discounts}/>}/>
                <Route path={"/user-management"} element={<AuthenticationGuard component={UserManagement}/>}/>
                <Route path="*" element={<Result
                    status="404"
                    title="404"
                    subTitle="Sorry, the page you visited does not exist."
                    extra={<Button type="primary">Back Home</Button>}
                />}/>
            </Route>
        </Routes>
    );
};
export default App;