import {Button, Col, Drawer, Flex, Radio, Row, Table,} from "antd";
import React, {useContext, useEffect, useRef, useState} from "react";
import {Text, Title} from "./Home";
import TextArea from "antd/lib/input/TextArea";
import {CartCounterContext} from "../components/cart/CartCounterContext";
import {TrashIcon} from "@primer/octicons-react";
import {CheckCircleOutlined, EditOutlined} from "@ant-design/icons";
import {AddressForm} from "../components/cart/AddressForm";
import moment from "moment";
import {useAuth0} from "@auth0/auth0-react";
import {useLazyQuery, useMutation} from "@apollo/client";
import {ADD_ORDER, CUSTOMER_QUERY, CUSTOMERS_QUERY} from "../apollo/apollo-queries";
import {useNavigate} from "react-router-dom";
import {AdminCartSelectCustomer} from "../components/cart/AdminCartSelectCustomer";
import {NotificationsContext} from "../components/common/NotificationsContext";
import {beautifyNumber, getDeliveryOption} from "../components/common/utils";
import {NewOrdersCounterContext} from "../components/orders/NewOrdersCounterContext";


export const Cart = () => {
    const {notifications} = useContext(NotificationsContext)
    const {refetch} = useContext(NewOrdersCounterContext)
    const {items, setItems, selectedCustomer, setSelectedCustomer} = useContext(CartCounterContext);
    const navigate = useNavigate();

    const [addOrder, {
        loading: orderUploading,
    }] = useMutation(ADD_ORDER, {
        errorPolicy: "all",
        fetchPolicy: "no-cache",
        onCompleted: () => {
            setItems([]);
            refetch();
            navigate('/ordini');
        },
        onError: (error => {
            notifications.error(`Errore nel server: ${error.message}`)
        })
    });

    const [fetchAccount, {data: account}] = useLazyQuery(CUSTOMER_QUERY, {
        fetchPolicy: "network-only",
        errorPolicy: "all",
        onError: error => {
            notifications.error(`Errore nel server: ${error.message}`)
        },
        onCompleted: (data) => {
            if (data.customer)
                setAddress(`${data.customer.street} ${data.customer.postalCode}, ${data.customer.city}, ${data.customer.state}`)
        }
    });

    const [fetchClients, {data: customers}] = useLazyQuery(CUSTOMERS_QUERY, {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
        onError: error => {
            notifications.error(`Errore nel server: ${error.message}`)
        },
        onCompleted: (data) => {
        }
    });

    const [visible, setVisible] = useState(false);
    const [adminCart, setAdminCart] = useState(false)
    const [cartTotal, setCartTotal] = useState(0);
    const [shipment, setShipment] = useState(0);
    const [address, setAddress] = useState("")
    const [alternativeData, setAlternativeData] = useState({
        alternativeDenomination: '',
        alternativePhone: '',
        alternativeAddress: ''
    });
    const {user} = useAuth0();
    const [standardInfo, setStandardInfo] = useState(true);
    const notesRef = useRef();
    const columns = [
        {
            title: 'Codice',
            dataIndex: 'code',
        },
        {
            title: 'Descrizione',
            dataIndex: 'description',
            width: '25%'
        },
        {
            title: 'Brand',
            dataIndex: 'brand',
        },
        {
            title: 'Consegna',
            dataIndex: 'deliveryOption',
            render: getDeliveryOption
        },
        {
            title: 'Quantità',
            dataIndex: 'quantity',
        },
        {
            title: (<div>Prezzo <br/> listino</div>),
            dataIndex: 'unitPrice',
            render: (item) => `${beautifyNumber(item.base + item.overprice + item.overpriceUS)} €`
        },
        {
            title: 'Sconto',
            key: 'discount',
            render: (_, record) => {
                return `${record.unitPrice.discount} %` ? `${record.unitPrice.discount} %` : 'n/d'
            }
        },
        {
            title: (<div>Totale <br/> listino</div>),
            render: (_, item) => `${beautifyNumber((item.unitPrice.base + item.unitPrice.overprice + item.unitPrice.overpriceUS) * item.quantity)} €`
        },
        {
            title: (<div>Totale <br/> scontato</div>),
            render: (_, {quantity, unitPrice}) => {
                return `${beautifyNumber(quantity * unitPrice.total)} €`
            },
        },
        {
            render: (_, record, index) => <Button
                type={"link"}
                icon={<TrashIcon/>}
                onClick={() => {
                    setItems((items) => items.filter((_, innerIdx) => innerIdx !== index))
                }}></Button>
        }
    ];
    const [orderDisabled, setOrderDisabled] = useState(true)

    useEffect(() => {
        setCartTotal(items.reduce((acc, {unitPrice: {total}, quantity}) => acc + (total * quantity), 0));
        if (cartTotal < 100) {
            setShipment(7);
        } else {
            setShipment(0);
        }
    }, [cartTotal, items]);

    useEffect(() => {
        const isAdmin = user["https://magnus-store.com/roles"].includes('admin')
        setAdminCart(isAdmin)
        if (isAdmin) {
            fetchClients();
        } else {
            fetchAccount({variables: {mail: user.email}});
        }
    }, [fetchAccount, fetchClients, user]);

    useEffect(() => {
        if (selectedCustomer) {
            fetchAccount({variables: {mail: selectedCustomer}});
        }
    }, [fetchAccount, selectedCustomer]);

    const vatApplicable = () => {
        const sc = customers?.customers.find(c => c.email === selectedCustomer);
        return (adminCart && !sc?.foreign) || (!adminCart && !account?.customer.foreign);
    }

    useEffect(() => {
        if (!account) {
            setOrderDisabled(true);
        } else if (!adminCart && standardInfo && (
            !account.customer.phone || account.customer.phone === '' ||
            !account.customer.street || account.customer.street === '' ||
            !account.customer.number || account.customer.number === '' ||
            !account.customer.postalCode || account.customer.postalCode === '' ||
            !account.customer.city || account.customer.city === '' ||
            !account.customer.state || account.customer.state === ''
        )) {
            alert("Aggiorna le tue informazioni di base per completare l'ordine")
            setOrderDisabled(true);
        } else if (!adminCart && !standardInfo && !visible && (
            alternativeData.alternativeDenomination === '' ||
            alternativeData.alternativePhone === '' ||
            alternativeData.alternativeAddress === ''
        )) {
            alert("Informazioni custom assenti o incomplete")
            setOrderDisabled(true);
        } else if (items.length === 0 || (adminCart && !selectedCustomer)) {
            setOrderDisabled(true);
        } else {
            setOrderDisabled(false);
        }
    }, [account, adminCart, alternativeData.alternativeAddress, alternativeData.alternativeDenomination, alternativeData.alternativePhone, items.length, selectedCustomer, standardInfo, visible]);

    return <Flex vertical gap={'large'} className={'h-100'}>
        <Flex justify={"start"}>
            <Title level={3}>Carrello</Title>
        </Flex>
        {
            adminCart &&
            <AdminCartSelectCustomer
                customers={customers?.customers ? customers.customers : []}
                onChange={setSelectedCustomer}
                selectedCustomer={selectedCustomer}
            />
        }
        <Table dataSource={items} columns={columns} pagination={{pageSize: 4}} rowKey={(_, index) => index}/>
        <Flex vertical={true} style={{flexGrow: 1}} gap={"large"} justify={"end"}>
            <div className={'filter-form'}>
                <Row style={{borderBottom: '1 px solid gray'}} className={'mb-2'}>
                    <Col span={5}><Text strong>Riepilogo</Text></Col>
                    <Col span={9}><Text strong>Note eventuali</Text></Col>
                    <Col span={9}><Text strong>Spedizione & Contatti</Text></Col>
                </Row>
                <Row gutter={16}>
                    <Col span={5}>
                        <Flex vertical={true}>
                            <span>Totale articoli nel carrello: {items.length}</span>
                            <span>Costo totale articoli: {beautifyNumber(cartTotal)} €</span>
                            <span>Spedizione: {shipment === 0 ? 'Gratuita' : `${beautifyNumber(shipment)} €`}</span>
                        </Flex>
                    </Col>
                    <Col span={9}>
                        <TextArea ref={notesRef}></TextArea>
                    </Col>
                    <Col span={9}>
                        <Radio.Group
                            onChange={(event) => {
                                setStandardInfo(event.target.value === 1);
                            }}
                            value={standardInfo ? 1 : 2}
                            className={'w-100'}
                        >
                            <Row>
                                <Col span={12}>
                                    <Radio value={1}> Standard </Radio>
                                </Col>
                                <Col span={12}>
                                    <Radio value={2}>
                                        Personalizzate
                                        {
                                            (!standardInfo) &&
                                            <Button
                                                type={"link"}
                                                onClick={() => setVisible(true)}
                                                icon={<EditOutlined/>}
                                            />
                                        }
                                    </Radio>
                                </Col>
                            </Row>
                        </Radio.Group>
                        <Row align={"middle"}>
                            <Flex vertical={true}>
                                <Text>
                                    {(!standardInfo && alternativeData.alternativeAddress !== '') ? alternativeData.alternativeAddress : address}
                                </Text>
                                <Text>
                                    {(!standardInfo && alternativeData.alternativeDenomination !== '') ? alternativeData.alternativeDenomination : account?.customer.name} -
                                    Tel.
                                    {(!standardInfo && alternativeData.alternativePhone !== '') ? alternativeData.alternativePhone : account?.customer.phone}
                                </Text>
                            </Flex>
                        </Row>
                    </Col>
                </Row>
            </div>
            <Flex className={'filter-form'} style={{backgroundColor: '#8B8B8B'}} align={'center'}>
                <Flex className={'w-50'} vertical={true}>
                    <Text style={{color: "white"}}>Totale ordine:</Text>
                    <Text
                        style={{color: "white"}}>{beautifyNumber(cartTotal + shipment)} €
                        {vatApplicable() && `(${beautifyNumber(Math.round(cartTotal * 122) / 100 + shipment)} € iva incl.)`}
                    </Text>
                </Flex>
                <Flex className={'w-50'} justify={"end"}>
                    <Button
                        icon={<CheckCircleOutlined/>}
                        type={"primary"}
                        loading={orderUploading}
                        onClick={async () => {
                            const order = {
                                customerNotes: notesRef.current.resizableTextArea.textArea.value,
                                orderDate: moment().format('YYYY-MM-DD HH:mm'),
                                status: 0,
                                items: items.map(item => {
                                    return {...item, status: 0};
                                }),
                                ...alternativeData
                            }
                            if (adminCart) {
                                order.customer = selectedCustomer
                            } else {
                                order.customer = user["https://magnus-namespace.com/email"]
                            }

                            await addOrder({variables: {order}})
                        }}
                        disabled={orderDisabled}
                    > Effettua l'ordine</Button>
                </Flex>
            </Flex>
        </Flex>
        <Drawer
            title={"Inserire informazioni alternative"}
            size={'large'}
            onClose={() => {
                setVisible(false)
            }}
            open={visible}>
            <Flex vertical={true}>
                <AddressForm
                    data={alternativeData}
                    handleSubmit={(value) => {
                        setAlternativeData(value)
                        setVisible(false)
                    }}/>
            </Flex>
        </Drawer>
    </Flex>
}
